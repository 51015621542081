import React from 'react'
import { AnimateKeyframes } from 'react-simple-animate'
import Logo from 'assets/images/GoTackle-Logo.svg'

interface LogoLoaderProps {
  /**
   * Duration of animation in seconds
   * @default 2
   */
  duration?: number

  /**
   * Width of the animation in pixels
   * @default 200
   */
  animationWidth?: number

  /**
   * Height of the logo in pixels (can also use tailwind classes)
   * @default "h-[2.4375rem]"
   */
  logoHeight?: string

  /**
   * Whether to show the loader in a full page overlay
   * @default true
   */
  fullPage?: boolean

  /**
   * Z-index of the loader
   * @default 9999
   */
  zIndex?: number
}

/**
 * An animated loader that shows the GoTackle logo moving from side to side
 */
const LogoLoader: React.FC<LogoLoaderProps> = ({
  duration = 2,
  animationWidth = 100,
  logoHeight = 'h-[2.4375rem]',
  fullPage = true,
  zIndex = 9999,
}) => {
  const halfWidth = animationWidth / 2

  // If fullPage is true, render with a fixed position covering the entire viewport
  if (fullPage) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          zIndex,
        }}
      >
        <AnimateKeyframes
          play
          iterationCount="infinite"
          duration={duration}
          keyframes={[
            `transform: translate(-${halfWidth}px, 0px)`,
            `transform: translate(${halfWidth}px, 0px)`,
            `transform: translate(0px, 0px)`,
          ]}
        >
          <img src={Logo} alt="GoTackle icon" className={`object-contain ${logoHeight}`} />
        </AnimateKeyframes>
      </div>
    )
  }

  // If fullPage is false, render inline without fixed positioning
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AnimateKeyframes
        play
        iterationCount="infinite"
        duration={duration}
        keyframes={[
          `transform: translate(-${halfWidth}px, 0px)`,
          `transform: translate(${halfWidth}px, 0px)`,
          `transform: translate(0px, 0px)`,
        ]}
      >
        <img src={Logo} alt="GoTackle icon" className={`object-contain ${logoHeight}`} />
      </AnimateKeyframes>
    </div>
  )
}

export default LogoLoader
