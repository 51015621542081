import { getAuthToken } from 'lib/auth'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { getUserProfileAction } from 'store/actions/user'
import redirectTo from 'utils/redirectTo'
import { bindActionCreators, Dispatch } from 'redux'
import { isEmpty } from 'lodash'
import { ROLES, USER_ROLES } from 'config'
import { userInitialRouting } from 'utils/helper'
import Loader from 'components/Loaders'

/**
 * UserRoute is used to support the react router and it renders the routes
 * which is marked as private or is only accessible authenticated users
 * @param {React.Component} component
 */
const UserRoute = ({ component, getUserProfileAction, ...rest }) => {
  const authCheckStarted = useRef(false)
  const [showLoginForm, setShowLoginForm] = useState(false)

  useEffect(() => {
    authCheckStarted.current = true

    let isMounted = true

    const checkAuthentication = async () => {
      try {
        const jwtToken = await getAuthToken()

        if (!isMounted) return

        if (jwtToken) {
          if (isEmpty(rest.tenantId)) {
            getUserProfileAction()
          } else {
            if (rest.userRole.includes(USER_ROLES.BUSINESS_OWNER)) {
              userInitialRouting(rest.user.user.user, rest.tenantId[0])
            } else {
              redirectTo(
                `/${
                  rest.userRole.includes(USER_ROLES.BSO_ADMIN) ? ROLES.BSO_ADMIN : ROLES.BSO_ADVISOR
                }/${rest.tenantId[0]}/dashboard`
              )
            }
          }
        } else {
          setShowLoginForm(true)
        }
      } catch (error) {
        console.error('Authentication check failed: ', error)
        // On error, show the login form as a fallback
        if (isMounted) {
          setShowLoginForm(true)
        }
      }
    }

    checkAuthentication()

    return () => {
      isMounted = false
    }
  }, [getUserProfileAction, rest.tenantId, rest.userRole, rest.user.user.user])

  const routeComponent = (props) => {
    if (authCheckStarted.current && !showLoginForm) {
      return <Loader loader="LogoLoader" />
    }

    return React.createElement(component, props)
  }

  return <Route {...rest} render={routeComponent} pageTitle="" />
}
const mapProps = (state: any) => {
  const user = state
  return {
    user,
    tenantId: user.user.user.tenantId,
    userRole: user.user.user.roles,
  }
}
function mapDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUserProfileAction: getUserProfileAction.STARTED,
    },
    dispatch
  )
}

export default connect(mapProps, mapDispatch)(UserRoute)
