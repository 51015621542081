import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
} from 'react-query'
import { fetcher } from '../lib/api-client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: string
  AWSDateTime: string
  AWSEmail: string
  AWSIPAddress: string
  AWSJSON: string
  AWSPhone: string
  AWSTime: string
  AWSTimestamp: number
  AWSURL: string
}

export type AccountingChartResponse = {
  __typename?: 'AccountingChartResponse'
  data: Array<AccountingReportOutput>
  status: ChartStatus
}

export enum AccountingCharts {
  BalanceSheet = 'balanceSheet',
  CashBalance = 'cashBalance',
  CashBalanceByMonth = 'cashBalanceByMonth',
  CashFlow = 'cashFlow',
  LiquidCash = 'liquidCash',
  Payments = 'payments',
  ProfitAndLoss = 'profitAndLoss',
}

export type AccountingReport = {
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type AccountingReportOutput =
  | CashBalanceByMonthReport
  | CashBalanceReport
  | LiquidCashReport
  | ProfitAndLossReport

export type AccountingToolEntry = {
  __typename?: 'AccountingToolEntry'
  amount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
}

export type ActivityLogInput = {
  accessToken?: InputMaybe<Scalars['String']>
  action: Scalars['String']
  logStatus?: InputMaybe<Scalars['String']>
  showClientActivity?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type AddTaskCommentInput = {
  content: Scalars['String']
  fileIds?: InputMaybe<Array<Scalars['String']>>
  parentId?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<Scalars['String']>>
  taskId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type AddUserNotificationInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  senderId: Scalars['String']
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userId: Scalars['String']
}

export type AddUserToGroupInput = {
  remove?: InputMaybe<Scalars['Boolean']>
  role: Scalars['String']
  tenantId: Scalars['String']
  userName: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type AdvisorProfile = {
  __typename?: 'AdvisorProfile'
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type Advisors = {
  __typename?: 'Advisors'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type ApiResponse = {
  __typename?: 'ApiResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Assessment = {
  __typename?: 'Assessment'
  assessmentType?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  isVisible?: Maybe<Scalars['Boolean']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionType?: Maybe<Scalars['String']>
  options?: Maybe<Scalars['AWSJSON']>
  prerequisites?: Maybe<Scalars['AWSJSON']>
  previous?: Maybe<Scalars['AWSJSON']>
  tenantId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type AssessmentResponse = {
  __typename?: 'AssessmentResponse'
  assessmentResponse?: Maybe<Array<AssessmentResponseOutput>>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  currentStage?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  playStage?: Maybe<Scalars['AWSJSON']>
  totalAttemptedItems?: Maybe<Scalars['Int']>
  totalItems?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  userId?: Maybe<Scalars['ID']>
}

export type AssessmentResponseInput = {
  EIN?: InputMaybe<Scalars['Boolean']>
  accountingMethod?: InputMaybe<Scalars['Boolean']>
  cageCode?: InputMaybe<Scalars['Boolean']>
  codeCageAndUniqueId?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  industryIcon?: InputMaybe<Scalars['Boolean']>
  isActiveGoal?: InputMaybe<Scalars['Boolean']>
  isBusinessEmail?: InputMaybe<Scalars['Boolean']>
  isCreateAliasesGmail?: InputMaybe<Scalars['Boolean']>
  isCreateBusinessPlan?: InputMaybe<Scalars['Boolean']>
  isCreateLogo?: InputMaybe<Scalars['Boolean']>
  isDevelopBudget?: InputMaybe<Scalars['Boolean']>
  isGoogleDomain?: InputMaybe<Scalars['Boolean']>
  isGoogleProfileImage?: InputMaybe<Scalars['Boolean']>
  isGoogleVoice?: InputMaybe<Scalars['Boolean']>
  isIndustry?: InputMaybe<Scalars['Boolean']>
  isMatrix?: InputMaybe<Scalars['Boolean']>
  isSetupFacebook?: InputMaybe<Scalars['Boolean']>
  isSetupInstagram?: InputMaybe<Scalars['Boolean']>
  isSetupLinkedin?: InputMaybe<Scalars['Boolean']>
  isSubIndustry?: InputMaybe<Scalars['Boolean']>
  isTopGoal?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['AWSJSON']>
  month?: InputMaybe<Scalars['Boolean']>
  naicsCode?: InputMaybe<Scalars['Boolean']>
  next?: InputMaybe<Scalars['String']>
  nigpCode?: InputMaybe<Scalars['Boolean']>
  optionType?: InputMaybe<Scalars['String']>
  playResponse?: InputMaybe<Scalars['AWSJSON']>
  previous?: InputMaybe<Array<Scalars['String']>>
  productCode?: InputMaybe<Scalars['Boolean']>
  questionLabel?: InputMaybe<Scalars['String']>
  recommendations?: InputMaybe<RecommendationsInput>
  registerBusinessStructure?: InputMaybe<Scalars['Boolean']>
  registrationYear?: InputMaybe<Scalars['Boolean']>
  samRegistration?: InputMaybe<Scalars['Boolean']>
  serviceCode?: InputMaybe<Scalars['Boolean']>
  showInReview?: InputMaybe<Scalars['Boolean']>
  submittedAt?: InputMaybe<Scalars['AWSDateTime']>
  uniqueEntityId?: InputMaybe<Scalars['Boolean']>
  uniqueId?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['AWSJSON']>
  year?: InputMaybe<Scalars['Boolean']>
}

export type AssessmentResponseOutput = {
  __typename?: 'AssessmentResponseOutput'
  EIN?: Maybe<Scalars['Boolean']>
  accountingMethod?: Maybe<Scalars['Boolean']>
  cageCode?: Maybe<Scalars['Boolean']>
  codeCageAndUniqueId?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  industryIcon?: Maybe<Scalars['Boolean']>
  isActiveGoal?: Maybe<Scalars['Boolean']>
  isBusinessEmail?: Maybe<Scalars['Boolean']>
  isCreateAliasesGmail?: Maybe<Scalars['Boolean']>
  isCreateBusinessPlan?: Maybe<Scalars['Boolean']>
  isCreateLogo?: Maybe<Scalars['Boolean']>
  isDevelopBudget?: Maybe<Scalars['Boolean']>
  isGoogleDomain?: Maybe<Scalars['Boolean']>
  isGoogleProfileImage?: Maybe<Scalars['Boolean']>
  isGoogleVoice?: Maybe<Scalars['Boolean']>
  isIndustry?: Maybe<Scalars['Boolean']>
  isMatrix?: Maybe<Scalars['Boolean']>
  isSetupFacebook?: Maybe<Scalars['Boolean']>
  isSetupInstagram?: Maybe<Scalars['Boolean']>
  isSetupLinkedin?: Maybe<Scalars['Boolean']>
  isSubIndustry?: Maybe<Scalars['Boolean']>
  isTopGoal?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['AWSJSON']>
  month?: Maybe<Scalars['Boolean']>
  naicsCode?: Maybe<Scalars['Boolean']>
  next?: Maybe<Scalars['String']>
  nigpCode?: Maybe<Scalars['Boolean']>
  optionType?: Maybe<Scalars['String']>
  playResponse?: Maybe<Scalars['AWSJSON']>
  previous?: Maybe<Array<Scalars['String']>>
  productCode?: Maybe<Scalars['Boolean']>
  questionLabel?: Maybe<Scalars['String']>
  recommendations?: Maybe<Recommendations>
  registerBusinessStructure?: Maybe<Scalars['Boolean']>
  samRegistration?: Maybe<Scalars['Boolean']>
  serviceCode?: Maybe<Scalars['Boolean']>
  showInReview?: Maybe<Scalars['Boolean']>
  submittedAt?: Maybe<Scalars['AWSDateTime']>
  uniqueEntityId?: Maybe<Scalars['Boolean']>
  uniqueId?: Maybe<Scalars['Boolean']>
  value?: Maybe<Scalars['AWSJSON']>
  year?: Maybe<Scalars['Boolean']>
}

export type AttendeesType = {
  __typename?: 'AttendeesType'
  email?: Maybe<Scalars['String']>
  organizer?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
  self?: Maybe<Scalars['Boolean']>
}

export type Attestation = {
  __typename?: 'Attestation'
  businessBankAccount?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  debtFinancing?: Maybe<Scalars['String']>
  documentId?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  financialGrowth?: Maybe<Scalars['String']>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  jobsCreated?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  pk?: Maybe<Scalars['String']>
  productSale?: Maybe<Scalars['String']>
  properName?: Maybe<Scalars['String']>
  race?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  signedCount?: Maybe<Scalars['String']>
  signedDocumentUrl?: Maybe<Scalars['String']>
  sk?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  targetAudience?: Maybe<Scalars['String']>
  tenantAdvisorStatus?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  tenantStatus?: Maybe<Scalars['String']>
  unsignedCount?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
}

export type AttestationInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  businessBankAccount?: InputMaybe<Scalars['String']>
  businessName?: InputMaybe<Scalars['String']>
  contractors?: InputMaybe<Scalars['String']>
  debtFinancing?: InputMaybe<Scalars['String']>
  education?: InputMaybe<Scalars['String']>
  financialGrowth?: InputMaybe<Scalars['String']>
  fullTimeEmployees?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  generateDocuSignUrl?: InputMaybe<Scalars['Boolean']>
  jobsCreated?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  newCustomer?: InputMaybe<Scalars['String']>
  partTimeEmployees?: InputMaybe<Scalars['String']>
  physicalLocation?: InputMaybe<Scalars['String']>
  productSale?: InputMaybe<Scalars['String']>
  race?: InputMaybe<Scalars['String']>
  redirectUrl: Scalars['String']
  registerBusinessStructureOptionId?: InputMaybe<Scalars['String']>
  revenue?: InputMaybe<Scalars['String']>
  serviceSale?: InputMaybe<Scalars['String']>
  targetAudience?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  volunteers?: InputMaybe<Scalars['String']>
  wages?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['String']>
}

export type AttestationResponse = {
  __typename?: 'AttestationResponse'
  data?: Maybe<Attestation>
  error?: Maybe<Scalars['String']>
}

export enum AttestationStatus {
  Signed = 'SIGNED',
  Suspended = 'SUSPENDED',
  Unsigned = 'UNSIGNED',
}

export type AttestationSummary = {
  __typename?: 'AttestationSummary'
  aggregationReportHistory?: Maybe<Array<Maybe<Attestation>>>
  newAggregatedReport?: Maybe<Attestation>
  signedCount?: Maybe<Scalars['Int']>
  submissionEndDate?: Maybe<Scalars['AWSDateTime']>
  submissionReminderDate?: Maybe<Scalars['AWSDateTime']>
  submissionReportDate?: Maybe<Scalars['AWSDateTime']>
  submissionStartDate?: Maybe<Scalars['AWSDateTime']>
  tenantId?: Maybe<Scalars['ID']>
  unsignedCount?: Maybe<Scalars['Int']>
}

export type AuthValue = {
  __typename?: 'AuthValue'
  AccessToken?: Maybe<Scalars['String']>
  Expires?: Maybe<Scalars['String']>
  RefreshToken?: Maybe<Scalars['String']>
}

export type AvailabilityHoursInput = {
  duration?: InputMaybe<Array<Scalars['String']>>
  timeSlots?: InputMaybe<Array<InputMaybe<TimeSlotsInput>>>
}

export type AvailabilityHoursObj = {
  __typename?: 'AvailabilityHoursObj'
  duration?: Maybe<Array<Scalars['String']>>
  timeSlots?: Maybe<Array<Maybe<TimeSlotsObj>>>
  timezone?: Maybe<Scalars['String']>
}

export type BoAdvisorsResponse = {
  __typename?: 'BOAdvisorsResponse'
  data?: Maybe<Array<Maybe<Advisors>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type BalanceSheet = {
  __typename?: 'BalanceSheet'
  assets?: Maybe<Array<Maybe<AccountingToolEntry>>>
  endDate?: Maybe<Scalars['String']>
  equity?: Maybe<Array<Maybe<AccountingToolEntry>>>
  liabilities?: Maybe<Array<Maybe<AccountingToolEntry>>>
  startDate?: Maybe<Scalars['String']>
}

export type BaseCondition = {
  __typename?: 'BaseCondition'
  destinationResource: DestinationResource
  isDynamic?: Maybe<Scalars['Boolean']>
  operation: ConditionRuleOperation
  value?: Maybe<Scalars['String']>
}

export enum BaseResource {
  BusinessProfile = 'BUSINESS_PROFILE',
  User = 'USER',
}

export type BookkeepingInfo = {
  __typename?: 'BookkeepingInfo'
  balanceSheet?: Maybe<BalanceSheet>
  cashFlow?: Maybe<CashFlow>
  payments?: Maybe<Array<Maybe<Payment>>>
  profitAndLoss?: Maybe<ProfitAndLoss>
}

export type BookmarkToolInput = {
  isBookmarked: Scalars['Boolean']
  toolId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type BookmarkToolResponse = {
  __typename?: 'BookmarkToolResponse'
  data?: Maybe<Tool>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type BusinessAccessInput = {
  businessId: Scalars['ID']
  email: Scalars['String']
  type: BusinessUserType
  userId: Scalars['ID']
}

export type BusinessAddress = {
  __typename?: 'BusinessAddress'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  hasBusinessAddress?: Maybe<Scalars['Boolean']>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

export type BusinessAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>
  addressLine2?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  hasBusinessAddress?: InputMaybe<Scalars['Boolean']>
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  zipCode?: InputMaybe<Scalars['String']>
}

export type BusinessAdvisorsData = {
  __typename?: 'BusinessAdvisorsData'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type BusinessGoal = {
  __typename?: 'BusinessGoal'
  availableExpertise?: Maybe<Scalars['String']>
  availableResources?: Maybe<Scalars['Int']>
  budget?: Maybe<Scalars['Float']>
  businessGoalDescription?: Maybe<Scalars['String']>
  category?: Maybe<BusinessGoalCategory>
  createdAt?: Maybe<Scalars['String']>
  dedicatedHoursPerWeek?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  expectedEndDate?: Maybe<Scalars['String']>
  experimentalCurrentDay?: Maybe<Scalars['Int']>
  filter?: Maybe<UserTasksFilter>
  goalId?: Maybe<Scalars['ID']>
  goalPriority?: Maybe<BusinessGoalPriority>
  goalTimeFrame?: Maybe<BusinessGoalTimeFrame>
  logo?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['AWSJSON']>
  name?: Maybe<Scalars['String']>
  pauseDate?: Maybe<Scalars['String']>
  plan?: Maybe<BusinessGoalPlan>
  progress?: Maybe<Scalars['Int']>
  resourcesAverageDedicatedTimePerWeek?: Maybe<Scalars['Int']>
  sessionId?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  strategyHistory?: Maybe<Array<StrategyHistory>>
  suggestedBy?: Maybe<Advisors>
  suggestedById?: Maybe<Scalars['String']>
  suggestedStatus?: Maybe<UserTaskSuggestedItemStatus>
  tenantId?: Maybe<Scalars['ID']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['ID']>
}

export enum BusinessGoalCategory {
  Communications = 'COMMUNICATIONS',
  CustomerService = 'CUSTOMER_SERVICE',
  Finance = 'FINANCE',
  Hr = 'HR',
  InformationTechnology = 'INFORMATION_TECHNOLOGY',
  Legal = 'LEGAL',
  Marketing = 'MARKETING',
  Operations = 'OPERATIONS',
  Production = 'PRODUCTION',
  ResearchAndDevelopment = 'RESEARCH_AND_DEVELOPMENT',
  Sales = 'SALES',
  Strategy = 'STRATEGY',
}

export enum BusinessGoalDateFilter {
  EntireGamePlan = 'ENTIRE_GAME_PLAN',
  NextMonth = 'NEXT_MONTH',
  NextQuarter = 'NEXT_QUARTER',
  NextWeek = 'NEXT_WEEK',
  ThisMonth = 'THIS_MONTH',
  ThisQuarter = 'THIS_QUARTER',
  ThisWeek = 'THIS_WEEK',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
}

export type BusinessGoalMilestone = {
  __typename?: 'BusinessGoalMilestone'
  descriptions?: Maybe<Scalars['String']>
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<UserTasksFilter>
  goalStatus?: Maybe<UserTaskItemStatus>
  isFumbled?: Maybe<Scalars['Boolean']>
  milestoneId?: Maybe<Scalars['ID']>
  milestoneSpan?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['ID']>
  riskAndChallenges?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  tasks?: Maybe<Array<UserTask>>
  timeline?: Maybe<Scalars['String']>
}

export type BusinessGoalMilestoneInput = {
  descriptions?: InputMaybe<Scalars['String']>
  expectedEndDate: Scalars['String']
  milestoneId: Scalars['ID']
  name: Scalars['String']
  order: Scalars['Int']
  planId?: InputMaybe<Scalars['ID']>
  riskAndChallenges?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
  status?: InputMaybe<UserTaskItemStatus>
  tasks?: InputMaybe<Array<UserTaskInput>>
  timeline?: InputMaybe<Scalars['String']>
}

export type BusinessGoalPlan = {
  __typename?: 'BusinessGoalPlan'
  expectedEndDate?: Maybe<Scalars['String']>
  filter?: Maybe<UserTasksFilter>
  goalId?: Maybe<Scalars['ID']>
  goalStatus?: Maybe<UserTaskItemStatus>
  isUndismissedFumbledState?: Maybe<Scalars['Boolean']>
  isUndismissedMicroCelebrationState?: Maybe<Scalars['Boolean']>
  milestones?: Maybe<Array<BusinessGoalMilestone>>
  name?: Maybe<Scalars['String']>
  pauseDate?: Maybe<Scalars['String']>
  pauseDuration?: Maybe<Scalars['Int']>
  planId?: Maybe<Scalars['ID']>
  progress?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['String']>
  state?: Maybe<BusinessGoalPlanState>
  strategy?: Maybe<Scalars['String']>
  tasks?: Maybe<Array<Maybe<UserTask>>>
  timeline?: Maybe<Scalars['String']>
}

export type BusinessGoalPlanInput = {
  category?: InputMaybe<Scalars['String']>
  expectedEndDate: Scalars['String']
  goalId?: InputMaybe<Scalars['ID']>
  milestones?: InputMaybe<Array<BusinessGoalMilestoneInput>>
  name: Scalars['String']
  pauseDate?: InputMaybe<Scalars['String']>
  pauseDuration?: InputMaybe<Scalars['Int']>
  planId: Scalars['ID']
  progress?: InputMaybe<Scalars['Int']>
  startDate: Scalars['String']
  state?: InputMaybe<BusinessGoalPlanState>
  timeline?: InputMaybe<Scalars['String']>
}

export enum BusinessGoalPlanState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export enum BusinessGoalPriority {
  HighPriority = 'HIGH_PRIORITY',
  LowPriority = 'LOW_PRIORITY',
  MediumPriority = 'MEDIUM_PRIORITY',
}

export enum BusinessGoalTimeFrame {
  LongTerm = 'LONG_TERM',
  MediumTerm = 'Medium_TERM',
  ShortTerm = 'SHORT_TERM',
}

export type BusinessGoalsFilterData = {
  categories?: InputMaybe<Array<BusinessGoalCategory>>
  isArchive?: InputMaybe<Scalars['Boolean']>
  priorities?: InputMaybe<Array<BusinessGoalPriority>>
  statuses?: InputMaybe<Array<UserTaskItemStatus>>
  timeFrames?: InputMaybe<Array<BusinessGoalTimeFrame>>
}

export enum BusinessIndustries {
  ArtsEntertainment = 'ArtsEntertainment',
  EducationSocialServices = 'EducationSocialServices',
  FoodAndRestaurantBar = 'FoodAndRestaurantBar',
  Other = 'Other',
  PersonalServices = 'PersonalServices',
  ProfessionalServices = 'ProfessionalServices',
  Retail = 'Retail',
}

export enum BusinessMetricsViewPermissionState {
  Granted = 'GRANTED',
  NotGranted = 'NOT_GRANTED',
  Requested = 'REQUESTED',
}

export type BusinessPhoneNumber = {
  __typename?: 'BusinessPhoneNumber'
  countryCode?: Maybe<Scalars['Int']>
  phoneNo?: Maybe<Scalars['Int']>
}

export type BusinessProfile = {
  __typename?: 'BusinessProfile'
  EIN?: Maybe<Scalars['String']>
  POS?: Maybe<Scalars['String']>
  POSOptionId?: Maybe<Scalars['String']>
  accountingMethod?: Maybe<Scalars['String']>
  accountingMethodOptionId?: Maybe<Scalars['String']>
  additionalContext?: Maybe<Scalars['AWSJSON']>
  additionalInfo?: Maybe<Scalars['String']>
  address?: Maybe<Address>
  advisors?: Maybe<Array<Advisors>>
  avatar?: Maybe<Scalars['String']>
  bookKeepingTool?: Maybe<Scalars['String']>
  bookKeepingToolOptionId?: Maybe<Scalars['String']>
  bookkeepingInfo?: Maybe<BookkeepingInfo>
  budget?: Maybe<Scalars['String']>
  businessAddress?: Maybe<BusinessAddress>
  businessBankAccount?: Maybe<Scalars['Boolean']>
  businessClassification?: Maybe<Scalars['String']>
  businessClassificationOptionId?: Maybe<Scalars['String']>
  businessDescription?: Maybe<Scalars['String']>
  businessEmail?: Maybe<Scalars['String']>
  businessLocations?: Maybe<Array<Scalars['String']>>
  businessLocationsOptionIds?: Maybe<Array<Scalars['String']>>
  businessMetricsViewPermission?: Maybe<BusinessMetricsViewPermissionState>
  businessName?: Maybe<Scalars['String']>
  businessNature?: Maybe<Scalars['String']>
  businessNatureOptionId?: Maybe<Scalars['String']>
  businessPhoneNumber?: Maybe<BusinessPhoneNumber>
  businessPlan?: Maybe<UserFile>
  businessStage?: Maybe<Scalars['String']>
  businessStageOptionId?: Maybe<Scalars['String']>
  businessVision?: Maybe<Scalars['String']>
  challenges?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  crmToolIds?: Maybe<Array<Scalars['String']>>
  dba?: Maybe<Scalars['String']>
  dbaNames?: Maybe<Array<Scalars['String']>>
  debtFinancing?: Maybe<Scalars['String']>
  domainName?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  employeeBenefit?: Maybe<Scalars['String']>
  exitPlan?: Maybe<Scalars['String']>
  exitPlanOptionId?: Maybe<Scalars['String']>
  expenses?: Maybe<Scalars['Float']>
  facebook?: Maybe<Scalars['String']>
  financeTrackingToolIds?: Maybe<Array<Scalars['String']>>
  financialGrowth?: Maybe<Scalars['String']>
  finicityBusinessId?: Maybe<Scalars['String']>
  finicityConsumerId?: Maybe<Scalars['String']>
  finicityCustomerId?: Maybe<Scalars['String']>
  foodSubIndustry?: Maybe<Array<Scalars['String']>>
  foodSubIndustryOptionIds?: Maybe<Array<Scalars['String']>>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  googleBusinessProfilePlays?: Maybe<Array<Scalars['String']>>
  googleBusinessProfilePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  googleWorkspacePlays?: Maybe<Array<Scalars['String']>>
  googleWorkspacePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  grossRevenueGoalForThisYear?: Maybe<Scalars['String']>
  hasAvatar?: Maybe<Scalars['Boolean']>
  hasBookKeepingTool?: Maybe<Scalars['String']>
  hasBookKeepingToolOptionId?: Maybe<Scalars['String']>
  hasBudget?: Maybe<Scalars['String']>
  hasBudgetOptionId?: Maybe<Scalars['String']>
  hasBusinessEmail?: Maybe<Scalars['String']>
  hasBusinessEmailOptionId?: Maybe<Scalars['String']>
  hasBusinessEmployees?: Maybe<Scalars['String']>
  hasBusinessEmployeesOptionId?: Maybe<Scalars['String']>
  hasBusinessPhoneNumber?: Maybe<Scalars['String']>
  hasBusinessPhoneNumberOptionId?: Maybe<Scalars['String']>
  hasDomainName?: Maybe<Scalars['String']>
  hasDomainNameOptionId?: Maybe<Scalars['String']>
  hasEIN?: Maybe<Scalars['Boolean']>
  hasEsignatureTool?: Maybe<Scalars['String']>
  hasEsignatureToolOptionId?: Maybe<Scalars['String']>
  hasFacebook?: Maybe<Scalars['Boolean']>
  hasGoogleBusinessProfile?: Maybe<Scalars['String']>
  hasGoogleBusinessProfileOptionId?: Maybe<Scalars['String']>
  hasGoogleChrome?: Maybe<Scalars['String']>
  hasGoogleChromeOptionId?: Maybe<Scalars['String']>
  hasGoogleWorkspace?: Maybe<Scalars['String']>
  hasGoogleWorkspaceOptionId?: Maybe<Scalars['String']>
  hasInstagram?: Maybe<Scalars['Boolean']>
  hasLinkedin?: Maybe<Scalars['Boolean']>
  hasLogo?: Maybe<Scalars['String']>
  hasLogoOptionId?: Maybe<Scalars['String']>
  hasLogoTrademarked?: Maybe<Scalars['String']>
  hasLogoTrademarkedOptionId?: Maybe<Scalars['String']>
  hasMoreLocations?: Maybe<Scalars['String']>
  hasMoreLocationsOptionId?: Maybe<Scalars['String']>
  hasPOS?: Maybe<Scalars['String']>
  hasPOSOptionId?: Maybe<Scalars['String']>
  hasPaymentProcessor?: Maybe<Scalars['String']>
  hasPaymentProcessorOptionId?: Maybe<Scalars['String']>
  hasPricingStrategies?: Maybe<Scalars['String']>
  hasPricingStrategiesOptionId?: Maybe<Scalars['String']>
  hasQuickBooks?: Maybe<Scalars['String']>
  hasQuickBooksOptionId?: Maybe<Scalars['String']>
  hasRegisterBusinessStructure?: Maybe<Scalars['Boolean']>
  hasTikTok?: Maybe<Scalars['Boolean']>
  hasTwitter?: Maybe<Scalars['Boolean']>
  hasWebsite?: Maybe<Scalars['Boolean']>
  hasYoutube?: Maybe<Scalars['Boolean']>
  haveStartedGeneratingSales?: Maybe<Scalars['Boolean']>
  hoursSpent?: Maybe<Scalars['String']>
  hoursSpentOptionId?: Maybe<Scalars['String']>
  hoursSpentPerMonth?: Maybe<Scalars['Int']>
  hoursSpentPerWeek?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
  instagram?: Maybe<Scalars['String']>
  jobsCreated?: Maybe<Scalars['String']>
  lastUpdatedBusinessPlan?: Maybe<Scalars['AWSDateTime']>
  lastYearRevenue?: Maybe<Scalars['String']>
  launchDate?: Maybe<Scalars['AWSDateTime']>
  learnPricingStrategies?: Maybe<Scalars['String']>
  learnPricingStrategiesOptionId?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  loginFrequency?: Maybe<Scalars['String']>
  logo?: Maybe<UserFile>
  metricTimeSeries?: Maybe<MetricTimeSeries>
  metricValue?: Maybe<MetricValue>
  minorityOwned?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  monthlyImpactReports?: Maybe<Array<UserFile>>
  motivation?: Maybe<Scalars['String']>
  naicsCode?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  noOfBusinessStarted?: Maybe<Scalars['String']>
  numberOfBusinessBankAccounts?: Maybe<Scalars['String']>
  onboardedAt?: Maybe<Scalars['String']>
  otherExitPlan?: Maybe<Scalars['String']>
  otherGoals?: Maybe<Array<Scalars['String']>>
  otherGoalsOptionIds?: Maybe<Array<Scalars['String']>>
  otherRegisteredBusinessStructure?: Maybe<Scalars['String']>
  otherWhereDoYouDoBusiness?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  paymentAcceptance?: Maybe<Scalars['String']>
  paymentAcceptanceOptionId?: Maybe<Scalars['String']>
  paymentAcceptancePlan?: Maybe<Scalars['String']>
  paymentAcceptancePlanOptionId?: Maybe<Scalars['String']>
  paymentApps?: Maybe<Array<Scalars['String']>>
  paymentAppsOptionIds?: Maybe<Array<Scalars['String']>>
  paymentProcessor?: Maybe<Scalars['String']>
  paymentProcessorOptionId?: Maybe<Scalars['String']>
  paymentTypes?: Maybe<Array<Scalars['String']>>
  paymentTypesOptionIds?: Maybe<Array<Scalars['String']>>
  personalGoals?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  pricingStrategies?: Maybe<Array<Scalars['String']>>
  pricingStrategiesOptionIds?: Maybe<Array<Scalars['String']>>
  productSale?: Maybe<Scalars['String']>
  productsAndServices?: Maybe<Array<Scalars['String']>>
  productsAndServicesOptionIds?: Maybe<Array<Scalars['String']>>
  quickBookPlays?: Maybe<Array<Scalars['String']>>
  quickBookPlaysOptionIds?: Maybe<Array<Scalars['String']>>
  race?: Maybe<Scalars['String']>
  registerBusinessStructure?: Maybe<Scalars['String']>
  registerBusinessStructureOptionId?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  revenueStatus?: Maybe<Scalars['String']>
  revenueStatusOptionId?: Maybe<Scalars['String']>
  saleGoal?: Maybe<Scalars['String']>
  sbaCertified?: Maybe<Scalars['String']>
  seasonalityDetails?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  servicesSubIndustry?: Maybe<Scalars['String']>
  servicesSubIndustryOptionId?: Maybe<Scalars['String']>
  squarePlays?: Maybe<Array<Scalars['String']>>
  squarePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  stripePlays?: Maybe<Array<Scalars['String']>>
  stripePlaysOptionIds?: Maybe<Array<Scalars['String']>>
  strongBusinessAreas?: Maybe<Array<Scalars['String']>>
  strongBusinessAreasOptionIds?: Maybe<Array<Scalars['String']>>
  supportAndHelp?: Maybe<Array<Scalars['String']>>
  supportAndHelpOptionIds?: Maybe<Array<Scalars['String']>>
  targetAudience?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  thisYearRevenue?: Maybe<Scalars['String']>
  tiktok?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  topGoalOptionId?: Maybe<Scalars['String']>
  totalEmployees?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  usePOS?: Maybe<Scalars['String']>
  usePOSOptionId?: Maybe<Scalars['String']>
  usePaymentProcessor?: Maybe<Scalars['String']>
  usePaymentProcessorOptionId?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  users?: Maybe<Array<BusinessUser>>
  veteranOwned?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  whereDoYouDoBusiness?: Maybe<Array<Scalars['String']>>
  whereDoYouDoBusinessOptionIds?: Maybe<Array<Scalars['String']>>
  year?: Maybe<Scalars['String']>
  yearEstablished?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
  youtube?: Maybe<Scalars['String']>
}

export type BusinessProfileMetricTimeSeriesArgs = {
  input: MetricTimeSeriesInput
}

export type BusinessProfileMetricValueArgs = {
  input: MetricValueInput
}

export type BusinessProfileFields = {
  __typename?: 'BusinessProfileFields'
  logo?: Maybe<Scalars['String']>
}

export type BusinessSummaryData = {
  __typename?: 'BusinessSummaryData'
  optionId?: Maybe<Scalars['String']>
  optionName?: Maybe<Scalars['String']>
  optionType?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['Int']>
  tenantId?: Maybe<Scalars['String']>
}

export type BusinessSummaryItem = {
  __typename?: 'BusinessSummaryItem'
  data: Array<BusinessSummaryData>
  optionType?: Maybe<Scalars['String']>
}

export type BusinessSummaryResponse = {
  __typename?: 'BusinessSummaryResponse'
  data: Array<BusinessSummaryItem>
  status: ChartStatus
}

export type BusinessSupportOrganization = {
  __typename?: 'BusinessSupportOrganization'
  adminCount?: Maybe<Scalars['Int']>
  adminId: Scalars['String']
  advisorCount?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  licenseCount?: Maybe<Scalars['Int']>
  licensesUsed?: Maybe<Scalars['Int']>
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  status?: Maybe<OrganizationStatus>
  terminology?: Maybe<Array<Term>>
}

export type BusinessUser = {
  __typename?: 'BusinessUser'
  businessId: Scalars['ID']
  createdAt: Scalars['AWSDateTime']
  id: Scalars['ID']
  type: BusinessUserType
  updatedAt: Scalars['AWSDateTime']
  user: UserProfile
  userId: Scalars['ID']
}

export enum BusinessUserType {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum Calendar {
  GoogleCalendar = 'GOOGLE_CALENDAR',
  Internal = 'INTERNAL',
  MicrosoftOffice_365 = 'MICROSOFT_OFFICE_365',
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  advisorId?: Maybe<Scalars['String']>
  advisorName?: Maybe<Scalars['String']>
  advisorProfiles?: Maybe<Scalars['AWSJSON']>
  attendees?: Maybe<Array<CalendarEventAttendee>>
  availability?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  end?: Maybe<CalendarEventDate>
  eventId?: Maybe<Scalars['String']>
  eventTime?: Maybe<Scalars['AWSDateTime']>
  eventType?: Maybe<Scalars['String']>
  externalReferenceIds?: Maybe<Array<CalendarEventExternalReferenceId>>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  isAllDay?: Maybe<Scalars['Boolean']>
  isBusy?: Maybe<Scalars['Boolean']>
  location?: Maybe<Scalars['String']>
  meetingDetailsHTML?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  organizerFirstName?: Maybe<Scalars['String']>
  organizerLastName?: Maybe<Scalars['String']>
  organizerName?: Maybe<Scalars['String']>
  originator?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  participantFirstName?: Maybe<Scalars['String']>
  participantLastName?: Maybe<Scalars['String']>
  participantName?: Maybe<Scalars['String']>
  profile?: Maybe<Scalars['AWSJSON']>
  start?: Maybe<CalendarEventDate>
  status?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  teamMemberList?: Maybe<Array<Maybe<Scalars['String']>>>
  timezone?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type CalendarEventAttendee = {
  __typename?: 'CalendarEventAttendee'
  email: Scalars['String']
  optional?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
}

export type CalendarEventDate = {
  __typename?: 'CalendarEventDate'
  date?: Maybe<Scalars['String']>
  dateTime?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type CalendarEventExternalReferenceId = {
  __typename?: 'CalendarEventExternalReferenceId'
  calendarId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  eventId: Scalars['String']
  externalEventId?: Maybe<Scalars['String']>
  meetingLink?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  pk?: Maybe<Scalars['String']>
  sk?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type: Scalars['String']
  userId: Scalars['String']
}

export type CalendarEventResponse = {
  __typename?: 'CalendarEventResponse'
  data: Array<CalendarEvent>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export enum CalendarEventViewPreference {
  All = 'ALL',
  Internal = 'INTERNAL',
}

export type CalendlyUser = {
  __typename?: 'CalendlyUser'
  calendlyEventsUrl?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CalendlyUserResponse = {
  __typename?: 'CalendlyUserResponse'
  data?: Maybe<CalendlyUser>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CancelUserCalendarEvent = {
  __typename?: 'CancelUserCalendarEvent'
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
}

export type CancelUserCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  tackleId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type CancelUserCalendarEventResponse = {
  __typename?: 'CancelUserCalendarEventResponse'
  data?: Maybe<CancelUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type CashBalanceByMonthReport = {
  __typename?: 'CashBalanceByMonthReport'
  cashBalance?: Maybe<Scalars['Float']>
  endDate?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
}

export type CashBalanceReport = AccountingReport & {
  __typename?: 'CashBalanceReport'
  cashBalance?: Maybe<Scalars['Float']>
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CashFlow = {
  __typename?: 'CashFlow'
  activities?: Maybe<Array<Maybe<AccountingToolEntry>>>
  endDate?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
}

export enum ChartStatus {
  DisconnectedSource = 'DisconnectedSource',
  Error = 'Error',
  Processing = 'Processing',
  Success = 'Success',
}

export type ChatBotMessage = {
  __typename?: 'ChatBotMessage'
  content: Scalars['String']
  createdAt: Scalars['String']
  messageType: Scalars['String']
  progress?: Maybe<Scalars['Int']>
  role: Scalars['String']
}

export enum ChatBotMessageTypes {
  Form = 'Form',
  FormResponse = 'FormResponse',
  Text = 'Text',
  ToolConnected = 'ToolConnected',
  Tools = 'Tools',
}

export type ChatSession = {
  __typename?: 'ChatSession'
  streamUrl: Scalars['String']
}

export enum ChatSessionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export type CognitoUser = {
  __typename?: 'CognitoUser'
  email: Scalars['String']
  enabled?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  userName: Scalars['String']
  userStatus?: Maybe<Scalars['String']>
}

export enum ConditionRuleOperation {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  NotContains = 'NOT_CONTAINS',
  NotEquals = 'NOT_EQUALS',
}

export type ConditionalField = {
  __typename?: 'ConditionalField'
  dependsOn: Scalars['String']
  excludeValues?: Maybe<Array<Scalars['String']>>
  values: Array<Scalars['String']>
}

export type ConditionalFieldInput = {
  dependsOn: Scalars['String']
  excludeValues?: InputMaybe<Array<Scalars['String']>>
  values: Array<Scalars['String']>
}

export type CounterResponse = {
  __typename?: 'CounterResponse'
  lastUpdatedAt?: Maybe<Scalars['AWSDateTime']>
  value?: Maybe<Scalars['Float']>
}

export type CreateChatSessionInput = {
  assistantId?: InputMaybe<Scalars['String']>
  onboardingFlowType?: InputMaybe<OnboardingFlowType>
  targetOrigin: Scalars['String']
  threadId?: InputMaybe<Scalars['String']>
}

export type CreateFlowChatSessionInput = {
  businessId?: InputMaybe<Scalars['String']>
  flow: Scalars['String']
  metadata?: InputMaybe<Scalars['AWSJSON']>
}

export type CreateToolInput = {
  description?: InputMaybe<Scalars['String']>
  featured?: InputMaybe<Scalars['Boolean']>
  imageFile?: InputMaybe<ToolImageFileInput>
  imageUrl?: InputMaybe<Scalars['String']>
  keyWords?: InputMaybe<Array<Scalars['String']>>
  longDescription?: InputMaybe<Scalars['String']>
  pricingPageUrl?: InputMaybe<Scalars['String']>
  supportLevel?: InputMaybe<ToolSupportLevel>
  tags?: InputMaybe<Array<Scalars['String']>>
  toolDisplayName?: InputMaybe<Scalars['String']>
  toolHelpText?: InputMaybe<Scalars['String']>
  toolName: Scalars['String']
  toolType?: InputMaybe<ToolType>
  toolUrl: Scalars['String']
  userToolsImageUrl: Scalars['String']
  visible?: InputMaybe<Scalars['Boolean']>
}

export type CreateUserInput = {
  advisors?: InputMaybe<Scalars['AWSJSON']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  mobileContactNumber: Scalars['AWSPhone']
  owners?: InputMaybe<Scalars['AWSJSON']>
  role: Scalars['String']
  suppressEmail?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type CreateUserTaskInput = {
  assigneeId?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<BusinessGoalCategory>
  createdById?: InputMaybe<Scalars['ID']>
  estimatedEffortInMinutes?: InputMaybe<Scalars['Int']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId?: InputMaybe<Scalars['String']>
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  kpis?: InputMaybe<Scalars['String']>
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
  planId?: InputMaybe<Scalars['ID']>
  resources?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source: UserTaskSource
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  taskPriority?: InputMaybe<BusinessGoalPriority>
  tenantId: Scalars['ID']
  timeline?: InputMaybe<Scalars['String']>
  tool?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  why?: InputMaybe<Scalars['String']>
}

export type CyclrConnector = {
  __typename?: 'CyclrConnector'
  authValue?: Maybe<AuthValue>
  authenticated?: Maybe<Scalars['Boolean']>
  connectorId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type CyclrConnectorResponse = {
  __typename?: 'CyclrConnectorResponse'
  data?: Maybe<Array<CyclrConnector>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export enum DateRanges {
  Last12Months = 'last12Months',
  LastMonth = 'lastMonth',
  LastQuarter = 'lastQuarter',
  LastYear = 'lastYear',
  LastYearToDate = 'lastYearToDate',
  ThisMonth = 'thisMonth',
  ThisQuarter = 'thisQuarter',
  ThisWeek = 'thisWeek',
  ThisYear = 'thisYear',
  ThisYearToDate = 'thisYearToDate',
}

export type DateValidationRule = {
  __typename?: 'DateValidationRule'
  dependsOn?: Maybe<Scalars['String']>
  dependsOnMessage?: Maybe<Scalars['String']>
  message: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type DateValidationRuleInput = {
  dependsOn?: InputMaybe<Scalars['String']>
  dependsOnMessage?: InputMaybe<Scalars['String']>
  message: Scalars['String']
  value?: InputMaybe<Scalars['String']>
}

export type DeauthenticateConnectorInput = {
  connectorName: Scalars['String']
  tenantId: Scalars['String']
}

export type DeleteCalendarEventInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
}

export type DeleteCalendarEventResponse = {
  __typename?: 'DeleteCalendarEventResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type DeleteTaskCommentInput = {
  commentId: Scalars['String']
  taskId: Scalars['String']
}

export type DeleteUserFilesInput = {
  recordIds: Array<Scalars['String']>
}

export type DeleteUserNotificationsInput = {
  deleteAll?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type DeleteUserTaskInput = {
  taskId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type DestinationResource = {
  __typename?: 'DestinationResource'
  baseResource: BaseResource
  field: Scalars['String']
}

export type DestinationResourceInput = {
  baseResource: BaseResource
  field: Scalars['String']
}

export enum ErrorType {
  DisconnectedToolError = 'DisconnectedToolError',
  FailedMeetingEmailInvites = 'FailedMeetingEmailInvites',
  ForceLogoutError = 'ForceLogoutError',
  LicenseLimitReached = 'LicenseLimitReached',
  ValidationError = 'ValidationError',
}

export enum FieldType {
  Button = 'BUTTON',
  ButtonSelect = 'BUTTON_SELECT',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  File = 'FILE',
  GoalTemplate = 'GOAL_TEMPLATE',
  Input = 'INPUT',
  Select = 'SELECT',
}

export type FileDetails = {
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  streamMessageId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type FileStructure = {
  __typename?: 'FileStructure'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type FileStructureInput = {
  id?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type GetAdvisorAssignedTasksInput = {
  filter?: InputMaybe<UserTasksFilterInput>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetAdvisorProfileInput = {
  advisorId: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAdvisorsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAllToolCategoriesResponse = {
  __typename?: 'GetAllToolCategoriesResponse'
  categories: Array<GetAllToolCategoriesResponseItem>
  subCategories: Array<GetAllToolCategoriesResponseItem>
}

export type GetAllToolCategoriesResponseItem = {
  __typename?: 'GetAllToolCategoriesResponseItem'
  clientDescription?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type GetAllToolsGroupedResponse = {
  __typename?: 'GetAllToolsGroupedResponse'
  data?: Maybe<Array<Maybe<GroupedTools>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetAllToolsResponse = {
  __typename?: 'GetAllToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  groupedTools?: Maybe<GroupedTools>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetAttestationReportsInput = {
  filter: AttestationStatus
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  page: Scalars['Int']
  pageSize: Scalars['Int']
  paginationDirection: PaginationDirection
  sort?: InputMaybe<SortOrder>
  tenantId: Scalars['String']
  year?: InputMaybe<Scalars['String']>
}

export type GetAttestationReportsResponse = {
  __typename?: 'GetAttestationReportsResponse'
  data?: Maybe<GetAttestationReportsResponseObj>
  error?: Maybe<Scalars['String']>
}

export type GetAttestationReportsResponseObj = {
  __typename?: 'GetAttestationReportsResponseObj'
  currentPage?: Maybe<Scalars['Int']>
  items?: Maybe<Array<Maybe<Attestation>>>
  lastEvaluatedKey?: Maybe<Scalars['String']>
  pageSize?: Maybe<Scalars['Int']>
  prevEvaluatedKey?: Maybe<Scalars['String']>
  totalPages?: Maybe<Scalars['Int']>
  totalRecords?: Maybe<Scalars['Int']>
}

export type GetAttestationSummaryInput = {
  advisorId: Scalars['ID']
  includeAggregationDetails?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['ID']
  timePeriod: TimePeriod
}

export type GetBusinessGoalInput = {
  filter?: InputMaybe<UserTasksFilterInput>
  goalId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetBusinessGoalsInput = {
  filter?: InputMaybe<BusinessGoalsFilterData>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetBusinessIndustryDetailsInput = {
  foodSubIndustryOptionIds?: InputMaybe<Array<Scalars['String']>>
  userId?: InputMaybe<Scalars['String']>
}

export type GetBusinessIndustryDetailsResponse = {
  __typename?: 'GetBusinessIndustryDetailsResponse'
  isSubIndustryUpdated?: Maybe<Scalars['Boolean']>
}

export type GetBusinessSummaryInput = {
  advisorId?: InputMaybe<Scalars['String']>
  optionType?: InputMaybe<OptionTypes>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetBusinessSupportOrganizationInput = {
  tenantId: Scalars['String']
}

export type GetBusinessesInput = {
  advisor?: InputMaybe<Scalars['String']>
  allStatuses: Scalars['Boolean']
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetChartDataInput = {
  businessId?: InputMaybe<Scalars['String']>
  chartName: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type GetChatSessionsInput = {
  userId?: InputMaybe<Scalars['String']>
}

export type GetClientsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  includeInvitedClients?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetClientsResponse = {
  __typename?: 'GetClientsResponse'
  data?: Maybe<Array<Maybe<GetClientsResponseData>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetClientsResponseData = {
  __typename?: 'GetClientsResponseData'
  bio?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  calendlyEventsUrl?: Maybe<Scalars['String']>
  companyAdvisor?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['Int']>
  createdAt: Scalars['AWSDateTime']
  email: Scalars['AWSEmail']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isNewUser?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['AWSPhone']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  schedulingUrl?: Maybe<Scalars['String']>
  updatedAt: Scalars['AWSDateTime']
  websiteURL?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type GetMeetingTypeResponse = {
  __typename?: 'GetMeetingTypeResponse'
  data?: Maybe<Array<Maybe<MeetingTypes>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetOAuthUrlInput = {
  authType?: InputMaybe<ToolAuthType>
  connectorName: Scalars['String']
  oAuthConnectionProperties?: InputMaybe<Array<OAuthConnectionProperty>>
  targetOrigin: Scalars['String']
}

export type GetOnboardingFlowStateInput = {
  flowType: OnboardingFlowType
  sessionId: Scalars['String']
}

export type GetOptionsResponse = {
  __typename?: 'GetOptionsResponse'
  data?: Maybe<Array<Maybe<Options>>>
  error?: Maybe<Scalars['String']>
}

export type GetPlayResponseInput = {
  playId?: InputMaybe<Scalars['String']>
  tabId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type GetPlaysInput = {
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type GetPlaysResponse = {
  __typename?: 'GetPlaysResponse'
  data?: Maybe<Array<Maybe<Play>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingResponse = {
  __typename?: 'GetTackleMeetingResponse'
  data?: Maybe<Array<Maybe<CalendarEvent>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  pagination?: Maybe<Scalars['AWSJSON']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingsInput = {
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  ownerId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetTaskCommentsInput = {
  taskId: Scalars['String']
}

export type GetTenantsResponse = {
  __typename?: 'GetTenantsResponse'
  data?: Maybe<Array<Maybe<Tenant>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetToolByDataTypesInput = {
  dataTypes: Array<ToolDataTypes>
  getOpenTools?: InputMaybe<Scalars['Boolean']>
  onboardingFlowType?: InputMaybe<OnboardingFlowType>
  targetOrigin: Scalars['String']
  userId: Scalars['String']
}

export type GetToolsByTypeInput = {
  tenantId: Scalars['String']
  toolType: ToolType
}

export type GetToolsByTypeResponse = {
  __typename?: 'GetToolsByTypeResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
}

export type GetToolsResponse = {
  __typename?: 'GetToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTopActiveClientsResponse = {
  __typename?: 'GetTopActiveClientsResponse'
  data?: Maybe<Array<Maybe<TopActiveClient>>>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailability = {
  __typename?: 'GetUserAvailability'
  availability: Array<UserAvailability>
  duration: Scalars['String']
}

export type GetUserAvailabilityHoursResponse = {
  __typename?: 'GetUserAvailabilityHoursResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailabilityInput = {
  duration: Scalars['Int']
  endTime?: InputMaybe<Scalars['String']>
  ignoreConflicts?: InputMaybe<Scalars['Boolean']>
  participantId?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Array<Scalars['String']>>
  startTime?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type GetUserAvailabilityResponse = {
  __typename?: 'GetUserAvailabilityResponse'
  data?: Maybe<GetUserAvailability>
  error?: Maybe<Scalars['String']>
  meetingLocation?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type GetUserMeetingByIdInput = {
  id: Scalars['String']
  tenantId: Scalars['String']
}

export type GetUserMeetingByIdResponse = {
  __typename?: 'GetUserMeetingByIdResponse'
  data?: Maybe<CalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type GetUserNotificationsInput = {
  countOnly?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetUserNotificationsResponse = {
  __typename?: 'GetUserNotificationsResponse'
  data?: Maybe<UserNotificationData>
  error?: Maybe<Scalars['String']>
}

export type GetUserRecommendationsInput = {
  assessmentType?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type GetUserTaskInput = {
  taskId: Scalars['ID']
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetUserTasksInput = {
  filter?: InputMaybe<UserTasksFilterInput>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type GetUserToolsResponse = {
  __typename?: 'GetUserToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type Goal = {
  __typename?: 'Goal'
  assessments?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isActiveGoal?: Maybe<Scalars['Boolean']>
  isTopGoal?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  orderOfSequence?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type GoogleCalendarWebhookMetaData = {
  __typename?: 'GoogleCalendarWebhookMetaData'
  expiration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  resourceUri?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type GroupTaskCompletionCondition = {
  __typename?: 'GroupTaskCompletionCondition'
  group?: Maybe<Array<TaskCompletionBaseCondition>>
}

export type GroupedTools = {
  __typename?: 'GroupedTools'
  category?: Maybe<Scalars['String']>
  tools?: Maybe<Array<Maybe<Tool>>>
}

export type IndustryReport = {
  __typename?: 'IndustryReport'
  results?: Maybe<Array<Maybe<IndustryReportResult>>>
  searchedKeyword?: Maybe<Scalars['String']>
  summaryText?: Maybe<Scalars['String']>
}

export type IndustryReportResult = {
  __typename?: 'IndustryReportResult'
  docTitle?: Maybe<Scalars['String']>
  docUrl?: Maybe<Scalars['String']>
  pageNumber?: Maybe<Scalars['Int']>
  summary?: Maybe<Scalars['String']>
}

export type JobDto = {
  __typename?: 'JobDto'
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['String']
  payload?: Maybe<Scalars['AWSJSON']>
  status: JobStatus
  type: JobTypes
  updatedAt?: Maybe<Scalars['String']>
}

export enum JobStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum JobTypes {
  ToolDataProcessing = 'ToolDataProcessing',
}

export type KeyValuePair = {
  __typename?: 'KeyValuePair'
  key: Scalars['String']
  value: Scalars['String']
}

export type KeyValuePairInput = {
  key: Scalars['String']
  value: Scalars['String']
}

export type LineItem = {
  __typename?: 'LineItem'
  amount?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
}

export type LiquidCashReport = AccountingReport & {
  __typename?: 'LiquidCashReport'
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  liquidCash?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type ListAssessment = {
  __typename?: 'ListAssessment'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  inProgress?: Maybe<Scalars['Boolean']>
  isCompleted?: Maybe<Scalars['Boolean']>
  isLocked?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ListAssessmentResponse = {
  __typename?: 'ListAssessmentResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ListCognitoUsersInput = {
  role: Scalars['String']
}

export type MakeAnnouncementInput = {
  fileId?: InputMaybe<Scalars['String']>
  receiverIds: Array<Scalars['String']>
  sendToEmail: Scalars['Boolean']
  sendToSms: Scalars['Boolean']
  tenantId: Scalars['String']
  text: Scalars['String']
}

export enum MeetingLocation {
  Custom = 'CUSTOM',
  GoogleMeet = 'GOOGLE_MEET',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  Zoom = 'ZOOM',
}

export type MeetingTypes = {
  __typename?: 'MeetingTypes'
  duration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type MessageChatSession = {
  __typename?: 'MessageChatSession'
  createdAt?: Maybe<Scalars['String']>
  flow?: Maybe<Scalars['String']>
  id: Scalars['String']
  metadata?: Maybe<Scalars['AWSJSON']>
  status?: Maybe<ChatSessionStatus>
  title?: Maybe<Scalars['String']>
}

export type MessageToBotResponse = {
  __typename?: 'MessageToBotResponse'
  hasMore: Scalars['Boolean']
  messages?: Maybe<Array<ChatBotMessage>>
  nextCursor?: Maybe<Scalars['String']>
}

export enum MetricChannel {
  Email = 'EMAIL',
  LandingPage = 'LANDING_PAGE',
  Offline = 'OFFLINE',
  Phone = 'PHONE',
  Website = 'WEBSITE',
}

export enum MetricGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type MetricTimeSeries = {
  __typename?: 'MetricTimeSeries'
  timeSeries?: Maybe<TimeSeriesResponse>
}

export type MetricTimeSeriesInput = {
  granularity: MetricGranularity
  metricCode: Scalars['ID']
  timeRange: TimeRangeInput
}

export type MetricValue = {
  __typename?: 'MetricValue'
  counter?: Maybe<CounterResponse>
}

export type MetricValueInput = {
  channel?: InputMaybe<MetricChannel>
  metricCode: Scalars['ID']
  source?: InputMaybe<Scalars['String']>
  targetUserId?: InputMaybe<Scalars['ID']>
  timeRange: TimeRangeValueInput
}

export type Mutation = {
  __typename?: 'Mutation'
  addTaskComment?: Maybe<TaskComment>
  addUserNotification?: Maybe<ApiResponse>
  addUserToGroup: Scalars['Boolean']
  authorizeConnector?: Maybe<ApiResponse>
  bookmarkTool?: Maybe<BookmarkToolResponse>
  cancelUserCalendarEvent?: Maybe<CancelUserCalendarEventResponse>
  connectFinicity: Scalars['String']
  createChatSession?: Maybe<ChatSession>
  createFlowChatSession: MessageChatSession
  createTool?: Maybe<Tool>
  createUser?: Maybe<UserProfile>
  createUserTask?: Maybe<UserTask>
  deauthenticateConnector?: Maybe<ApiResponse>
  deleteCalendarEvent?: Maybe<DeleteCalendarEventResponse>
  deleteTaskComment?: Maybe<Scalars['Boolean']>
  deleteUserFiles?: Maybe<Scalars['Boolean']>
  deleteUserNotifications?: Maybe<ApiResponse>
  deleteUserTask?: Maybe<Scalars['String']>
  listCognitoUsers: Array<Maybe<CognitoUser>>
  logActivity?: Maybe<ApiResponse>
  makeAnnouncement?: Maybe<Scalars['Boolean']>
  populateTaskInformation: PopulateTaskInformationResponse
  processToolBusinessData: Scalars['String']
  regeneratePlan?: Maybe<Scalars['Boolean']>
  regeneratePlanAdmin?: Maybe<Scalars['Boolean']>
  resendUserInvite?: Maybe<Scalars['Boolean']>
  saveAssessmentResponse?: Maybe<AssessmentResponse>
  saveAttestation?: Maybe<AttestationResponse>
  saveBusinessGoal?: Maybe<Scalars['String']>
  savePlayResponse?: Maybe<PlayResponse>
  saveUserFile?: Maybe<ApiResponse>
  searchHowToDoTask: UserTaskHowToResponse
  sendMessageToBot: SendMessageToBotResponse
  setUserAvailability?: Maybe<SetUserAvailabilityResponse>
  setUserMeeting?: Maybe<UserMeetingData>
  setupFinicityUser?: Maybe<Scalars['Boolean']>
  subscribeGoogleCalendarWebhook?: Maybe<SubscribeGoogleCalendarWebhookResponse>
  summarySessionTitle: MessageChatSession
  toolConnectionSuccess?: Maybe<ApiResponse>
  updateBusinessGoal?: Maybe<Scalars['String']>
  updateBusinessGoalMilestone?: Maybe<Scalars['String']>
  updateBusinessInformation?: Maybe<BusinessProfile>
  updateBusinessProfile?: Maybe<BusinessProfile>
  updateGoalPlan?: Maybe<Scalars['String']>
  updateMyProfile?: Maybe<UserProfile>
  updateOwner?: Maybe<ApiResponse>
  updateTaskComment?: Maybe<TaskComment>
  updateTaskProgress?: Maybe<TaskResult>
  updateTaskReaction?: Maybe<Scalars['Boolean']>
  updateTool?: Maybe<Tool>
  updateUserAvailability?: Maybe<UpdateUserAvailabilityResponse>
  updateUserCalendarEvent?: Maybe<UpdateUserCalendarEventResponse>
  updateUserNotifications?: Maybe<ApiResponse>
  updateUserPassword?: Maybe<Scalars['Boolean']>
  updateUserTask?: Maybe<UserTask>
}

export type MutationAddTaskCommentArgs = {
  input: AddTaskCommentInput
}

export type MutationAddUserNotificationArgs = {
  input?: InputMaybe<AddUserNotificationInput>
}

export type MutationAddUserToGroupArgs = {
  input: AddUserToGroupInput
}

export type MutationAuthorizeConnectorArgs = {
  authValue: Scalars['String']
}

export type MutationBookmarkToolArgs = {
  input: BookmarkToolInput
}

export type MutationCancelUserCalendarEventArgs = {
  input: CancelUserCalendarEventInput
}

export type MutationConnectFinicityArgs = {
  institutionId?: InputMaybe<Scalars['Int']>
  redirectUrl: Scalars['String']
  tenantId: Scalars['String']
}

export type MutationCreateChatSessionArgs = {
  input: CreateChatSessionInput
}

export type MutationCreateFlowChatSessionArgs = {
  input: CreateFlowChatSessionInput
}

export type MutationCreateToolArgs = {
  input: CreateToolInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCreateUserTaskArgs = {
  input: CreateUserTaskInput
}

export type MutationDeauthenticateConnectorArgs = {
  input?: InputMaybe<DeauthenticateConnectorInput>
}

export type MutationDeleteCalendarEventArgs = {
  input: DeleteCalendarEventInput
}

export type MutationDeleteTaskCommentArgs = {
  input: DeleteTaskCommentInput
}

export type MutationDeleteUserFilesArgs = {
  input: DeleteUserFilesInput
}

export type MutationDeleteUserNotificationsArgs = {
  input?: InputMaybe<DeleteUserNotificationsInput>
}

export type MutationDeleteUserTaskArgs = {
  input: DeleteUserTaskInput
}

export type MutationListCognitoUsersArgs = {
  input: ListCognitoUsersInput
}

export type MutationLogActivityArgs = {
  activityLogInput: ActivityLogInput
}

export type MutationMakeAnnouncementArgs = {
  input: MakeAnnouncementInput
}

export type MutationPopulateTaskInformationArgs = {
  taskName: Scalars['String']
}

export type MutationProcessToolBusinessDataArgs = {
  input: ProcessToolBusinessDataInput
}

export type MutationRegeneratePlanArgs = {
  input: RegeneratePlanInput
}

export type MutationRegeneratePlanAdminArgs = {
  input: RegeneratePlanAdminInput
}

export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput
}

export type MutationSaveAssessmentResponseArgs = {
  input: SaveAssessmentResponseInput
}

export type MutationSaveAttestationArgs = {
  input: AttestationInput
}

export type MutationSaveBusinessGoalArgs = {
  input: SaveBusinessGoalInput
}

export type MutationSavePlayResponseArgs = {
  input: SavePlayResponseInput
}

export type MutationSaveUserFileArgs = {
  input: SaveUserFileInput
}

export type MutationSearchHowToDoTaskArgs = {
  input: SearchHowToDoTaskInput
}

export type MutationSendMessageToBotArgs = {
  input: SendMessageToBotInput
}

export type MutationSetUserAvailabilityArgs = {
  input?: InputMaybe<SetUserAvailabilityInput>
}

export type MutationSetUserMeetingArgs = {
  input: SetUserMeetingInput
}

export type MutationSubscribeGoogleCalendarWebhookArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type MutationSummarySessionTitleArgs = {
  id: Scalars['String']
}

export type MutationToolConnectionSuccessArgs = {
  input?: InputMaybe<ToolConnectionSuccessInput>
}

export type MutationUpdateBusinessGoalArgs = {
  input: UpdateBusinessGoalInput
}

export type MutationUpdateBusinessGoalMilestoneArgs = {
  input: UpdateBusinessGoalMilestoneInput
}

export type MutationUpdateBusinessInformationArgs = {
  profile: UpdateBusinessProfileInput
}

export type MutationUpdateBusinessProfileArgs = {
  input: UpdateBusinessProfileInput
}

export type MutationUpdateGoalPlanArgs = {
  input: UpdateGoalPlanInput
}

export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput
}

export type MutationUpdateOwnerArgs = {
  input?: InputMaybe<UpdateOwnerInput>
}

export type MutationUpdateTaskCommentArgs = {
  input: UpdateTaskCommentInput
}

export type MutationUpdateTaskProgressArgs = {
  id: Scalars['ID']
  output?: InputMaybe<Scalars['AWSJSON']>
  status?: InputMaybe<TaskResultStatus>
}

export type MutationUpdateTaskReactionArgs = {
  input: UpdateTaskReactionInput
}

export type MutationUpdateToolArgs = {
  input: UpdateToolInput
}

export type MutationUpdateUserAvailabilityArgs = {
  input?: InputMaybe<UpdateUserAvailabilityInput>
}

export type MutationUpdateUserCalendarEventArgs = {
  input: UpdateUserCalendarEventInput
}

export type MutationUpdateUserNotificationsArgs = {
  input?: InputMaybe<UpdateUserNotificationsInput>
}

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput
}

export type MutationUpdateUserTaskArgs = {
  input: UpdateUserTaskInput
}

export type OAuthConnectionProperty = {
  name: Scalars['String']
  value: Scalars['String']
}

export type OAuthUrlResponse = {
  __typename?: 'OAuthUrlResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type OnboardingFlowState = {
  __typename?: 'OnboardingFlowState'
  currentStepType: OnboardingFlowStepTypes
  executorId?: Maybe<Scalars['String']>
  flowType?: Maybe<OnboardingFlowType>
  metadata: Scalars['AWSJSON']
  sessionId: Scalars['String']
  status: OnboardingFlowStepStatus
  step: Scalars['String']
  tenantId?: Maybe<Scalars['String']>
  toolProcessingData?: Maybe<Array<Scalars['AWSJSON']>>
  userId: Scalars['String']
}

export enum OnboardingFlowStepStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  Pending = 'Pending',
}

export enum OnboardingFlowStepTypes {
  End = 'End',
  Form = 'Form',
  GoalTemplate = 'GoalTemplate',
  Message = 'Message',
  Question = 'Question',
  Review = 'Review',
  ToolConnection = 'ToolConnection',
}

export enum OnboardingFlowType {
  AcceptGoal = 'AcceptGoal',
  AddGoal = 'AddGoal',
  GetToKnowYou = 'GetToKnowYou',
  GoalSetting = 'GoalSetting',
  RegeneratePlan = 'RegeneratePlan',
  SuggestGoal = 'SuggestGoal',
}

export enum OnboardingStatus {
  Complete = 'COMPLETE',
  Intake = 'INTAKE',
  Onboarding = 'ONBOARDING',
}

export type Option = {
  __typename?: 'Option'
  label: Scalars['String']
  value: Scalars['String']
}

export type OptionInput = {
  label: Scalars['String']
  value: Scalars['String']
}

export enum OptionTypes {
  BusinessClassification = 'businessClassification',
  StrongBusinessAreas = 'strongBusinessAreas',
  SupportAndHelp = 'supportAndHelp',
  TopGoal = 'topGoal',
}

export type Options = {
  __typename?: 'Options'
  category?: Maybe<Scalars['String']>
  categoryType?: Maybe<Scalars['String']>
  dependencies?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  infoText?: Maybe<Scalars['String']>
  isNew?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['String']>
  toolTipText?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

export enum OrganizationStatus {
  Live = 'LIVE',
  Test = 'TEST',
}

export type OwnerActivitySummary = {
  __typename?: 'OwnerActivitySummary'
  avgMinsPerSession?: Maybe<Scalars['Float']>
  chats?: Maybe<Scalars['Int']>
  meetingsReScheduled?: Maybe<Scalars['Int']>
  meetingsScheduled?: Maybe<Scalars['Int']>
  signInPerWeek?: Maybe<Scalars['Float']>
  timeOnPlatform?: Maybe<Scalars['String']>
  timesSignedIn?: Maybe<Scalars['Int']>
  toolsIntegrated?: Maybe<Scalars['Int']>
}

export enum PaginationDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD',
}

export type Payment = {
  __typename?: 'Payment'
  customerId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  lineItems?: Maybe<Array<Maybe<LineItem>>>
  totalAmt?: Maybe<Scalars['Float']>
  txnDate?: Maybe<Scalars['String']>
}

export type Play = {
  __typename?: 'Play'
  content?: Maybe<Scalars['AWSJSON']>
  description?: Maybe<Scalars['String']>
  guide?: Maybe<Scalars['AWSJSON']>
  guide1?: Maybe<Scalars['AWSJSON']>
  id: Scalars['ID']
  meta?: Maybe<Scalars['AWSJSON']>
  playTabs?: Maybe<Scalars['AWSJSON']>
  recommendedBy?: Maybe<Scalars['String']>
  setup?: Maybe<Scalars['AWSJSON']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  use?: Maybe<Scalars['AWSJSON']>
}

export type PlayBusinessProfileFields = {
  logo?: InputMaybe<Scalars['String']>
}

export type PlayResponse = {
  __typename?: 'PlayResponse'
  businessProfileFields?: Maybe<BusinessProfileFields>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  completedBy?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  playId?: Maybe<Scalars['String']>
  playTabResponse?: Maybe<Array<TabResponse>>
  playType?: Maybe<Scalars['String']>
  recommendations?: Maybe<Array<Scalars['String']>>
  tenantId?: Maybe<Scalars['String']>
}

export type PopulateTaskInformationResponse = {
  __typename?: 'PopulateTaskInformationResponse'
  category: BusinessGoalCategory
  estimatedMinutes: Scalars['Int']
}

export type ProcessToolBusinessDataInput = {
  dataType: ToolDataTypes
  toolId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type ProfitAndLoss = {
  __typename?: 'ProfitAndLoss'
  endDate?: Maybe<Scalars['String']>
  expenses?: Maybe<Array<Maybe<AccountingToolEntry>>>
  income?: Maybe<Array<Maybe<AccountingToolEntry>>>
  profit?: Maybe<Array<Maybe<AccountingToolEntry>>>
  startDate?: Maybe<Scalars['String']>
}

export type ProfitAndLossReport = AccountingReport & {
  __typename?: 'ProfitAndLossReport'
  dateRange?: Maybe<DateRanges>
  endDate?: Maybe<Scalars['String']>
  growth?: Maybe<Scalars['Float']>
  profitAndLossNetIncome?: Maybe<Scalars['Float']>
  source?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  cancelOutlookEvent?: Maybe<ApiResponse>
  deleteGoogleCalendarEvent?: Maybe<ApiResponse>
  getAccountingCharts?: Maybe<AccountingChartResponse>
  getActivityLogs?: Maybe<ApiResponse>
  getAdvisorAssignedTasks?: Maybe<Array<UserTask>>
  getAdvisorDashboard?: Maybe<ApiResponse>
  getAdvisorProfile?: Maybe<AdvisorProfile>
  getAdvisors?: Maybe<ApiResponse>
  getAllToolCategories: GetAllToolCategoriesResponse
  getAllTools?: Maybe<GetAllToolsResponse>
  getAssessmentPlayListing?: Maybe<ApiResponse>
  getAssessmentResponseByQuestion?: Maybe<UserResponse>
  getAssessments?: Maybe<Array<Maybe<Assessment>>>
  getAttestationReports?: Maybe<GetAttestationReportsResponse>
  getAttestationSummary?: Maybe<AttestationSummary>
  getBoAdvisors?: Maybe<BoAdvisorsResponse>
  getBusinessGoal?: Maybe<BusinessGoal>
  getBusinessGoals?: Maybe<Array<BusinessGoal>>
  getBusinessIndustryDetails?: Maybe<GetBusinessIndustryDetailsResponse>
  getBusinessProfile?: Maybe<BusinessProfile>
  getBusinessSummary?: Maybe<BusinessSummaryResponse>
  getBusinessSupportOrganization?: Maybe<BusinessSupportOrganization>
  getBusinessUsers: Array<BusinessUser>
  getBusinesses?: Maybe<ApiResponse>
  getCalendlyUser?: Maybe<CalendlyUserResponse>
  getChartData?: Maybe<ApiResponse>
  getChatSessions?: Maybe<Array<MessageChatSession>>
  getClients?: Maybe<GetClientsResponse>
  getClientsSummary?: Maybe<ApiResponse>
  getDashboardListing?: Maybe<ApiResponse>
  getFile?: Maybe<ApiResponse>
  getGoalDetails?: Maybe<Goal>
  getMeetingTypes?: Maybe<GetMeetingTypeResponse>
  getMessagesFromBot: MessageToBotResponse
  getMyProfile: UserProfile
  getOAuthUrl?: Maybe<OAuthUrlResponse>
  getOnboardingFlowState?: Maybe<OnboardingFlowState>
  getOptions?: Maybe<GetOptionsResponse>
  getOwnerActivityDetails?: Maybe<OwnerActivitySummary>
  getOwnerBusinessData?: Maybe<ApiResponse>
  getPendingJobs: Array<JobDto>
  getPlay?: Maybe<ApiResponse>
  getPlayResponse?: Maybe<Array<PlayResponse>>
  getPlays?: Maybe<GetPlaysResponse>
  getQuote: QuoteResponse
  getSignedUrl?: Maybe<SignedUrlResponse>
  getTackleMeetings?: Maybe<GetTackleMeetingResponse>
  getTaskComments: Array<TaskComment>
  getTenants?: Maybe<GetTenantsResponse>
  getToolById?: Maybe<Tool>
  getTools?: Maybe<GetToolsResponse>
  getToolsByDataTypes: Array<Tool>
  getToolsByType?: Maybe<GetToolsByTypeResponse>
  getTopActiveClients?: Maybe<GetTopActiveClientsResponse>
  getUserAssessmentResponse?: Maybe<UserAssessmentResponse>
  getUserAvailability?: Maybe<GetUserAvailabilityResponse>
  getUserAvailabilityHours?: Maybe<GetUserAvailabilityHoursResponse>
  getUserCalendarEvents?: Maybe<CalendarEventResponse>
  getUserConnectors?: Maybe<CyclrConnectorResponse>
  getUserFiles?: Maybe<ApiResponse>
  getUserGoals?: Maybe<Array<Maybe<Goal>>>
  getUserIntegrations?: Maybe<ApiResponse>
  getUserMeeting?: Maybe<ApiResponse>
  getUserMeetingById?: Maybe<GetUserMeetingByIdResponse>
  getUserNotifications?: Maybe<GetUserNotificationsResponse>
  getUserRecommendations?: Maybe<UserRecommendationsResponse>
  getUserSummaries?: Maybe<UserSummaryResponse>
  getUserTask?: Maybe<UserTask>
  getUserTasks?: Maybe<Array<UserTask>>
  getUserTools?: Maybe<GetUserToolsResponse>
  healthCheck?: Maybe<Scalars['String']>
  listAssessments?: Maybe<ListAssessmentResponse>
  metricValue?: Maybe<MetricValue>
  retrieveBusinessInformation?: Maybe<BusinessProfile>
  searchBusinessInformation?: Maybe<BusinessProfile>
  searchIndustryReport?: Maybe<IndustryReport>
  searchTools?: Maybe<SearchToolsResponse>
  searchUserSummaries?: Maybe<UserSummaryResponse>
  subscribeCalendlyWebhook?: Maybe<ApiResponse>
  updateGoogleCalendarEvent?: Maybe<ApiResponse>
}

export type QueryCancelOutlookEventArgs = {
  input?: InputMaybe<CancelOutlookEventInput>
}

export type QueryDeleteGoogleCalendarEventArgs = {
  input?: InputMaybe<DeleteGoogleCalendarEventInput>
}

export type QueryGetAccountingChartsArgs = {
  input: QueryAccountingReportInput
}

export type QueryGetActivityLogsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorAssignedTasksArgs = {
  input: GetAdvisorAssignedTasksInput
}

export type QueryGetAdvisorDashboardArgs = {
  filterType?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorProfileArgs = {
  input: GetAdvisorProfileInput
}

export type QueryGetAdvisorsArgs = {
  getAdvisorsInput?: InputMaybe<GetAdvisorsInput>
}

export type QueryGetAllToolsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentPlayListingArgs = {
  tenantId: Scalars['String']
}

export type QueryGetAssessmentResponseByQuestionArgs = {
  question?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentsArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type QueryGetAttestationReportsArgs = {
  input: GetAttestationReportsInput
}

export type QueryGetAttestationSummaryArgs = {
  input?: InputMaybe<GetAttestationSummaryInput>
}

export type QueryGetBoAdvisorsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetBusinessGoalArgs = {
  input: GetBusinessGoalInput
}

export type QueryGetBusinessGoalsArgs = {
  input: GetBusinessGoalsInput
}

export type QueryGetBusinessIndustryDetailsArgs = {
  input: GetBusinessIndustryDetailsInput
}

export type QueryGetBusinessProfileArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryGetBusinessSummaryArgs = {
  input: GetBusinessSummaryInput
}

export type QueryGetBusinessSupportOrganizationArgs = {
  input: GetBusinessSupportOrganizationInput
}

export type QueryGetBusinessUsersArgs = {
  businessId: Scalars['ID']
}

export type QueryGetBusinessesArgs = {
  getBusinessesInput: GetBusinessesInput
}

export type QueryGetChartDataArgs = {
  input: GetChartDataInput
}

export type QueryGetChatSessionsArgs = {
  input: GetChatSessionsInput
}

export type QueryGetClientsArgs = {
  input?: InputMaybe<GetClientsInput>
}

export type QueryGetClientsSummaryArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetDashboardListingArgs = {
  category?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetFileArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetGoalDetailsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetMeetingTypesArgs = {
  tenantId: Scalars['String']
}

export type QueryGetMessagesFromBotArgs = {
  cursor?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  sessionId: Scalars['String']
}

export type QueryGetMyProfileArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetOAuthUrlArgs = {
  input: GetOAuthUrlInput
}

export type QueryGetOnboardingFlowStateArgs = {
  input: GetOnboardingFlowStateInput
}

export type QueryGetOptionsArgs = {
  optionTypes: Array<Scalars['String']>
}

export type QueryGetOwnerActivityDetailsArgs = {
  ownerId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetOwnerBusinessDataArgs = {
  input?: InputMaybe<GetOwnerBusinessDataInput>
}

export type QueryGetPlayArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetPlayResponseArgs = {
  input: GetPlayResponseInput
}

export type QueryGetPlaysArgs = {
  input?: InputMaybe<GetPlaysInput>
}

export type QueryGetSignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
}

export type QueryGetTackleMeetingsArgs = {
  input?: InputMaybe<GetTackleMeetingsInput>
}

export type QueryGetTaskCommentsArgs = {
  input: GetTaskCommentsInput
}

export type QueryGetToolByIdArgs = {
  toolId: Scalars['String']
}

export type QueryGetToolsByDataTypesArgs = {
  input: GetToolByDataTypesInput
}

export type QueryGetToolsByTypeArgs = {
  input: GetToolsByTypeInput
}

export type QueryGetTopActiveClientsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAssessmentResponseArgs = {
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAvailabilityArgs = {
  input: GetUserAvailabilityInput
}

export type QueryGetUserAvailabilityHoursArgs = {
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserCalendarEventsArgs = {
  calendarName?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeMax?: InputMaybe<Scalars['String']>
  timeMin?: InputMaybe<Scalars['String']>
}

export type QueryGetUserConnectorsArgs = {
  connectorName?: InputMaybe<Scalars['String']>
}

export type QueryGetUserFilesArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserGoalsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserIntegrationsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingArgs = {
  advisorId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingByIdArgs = {
  input: GetUserMeetingByIdInput
}

export type QueryGetUserNotificationsArgs = {
  input?: InputMaybe<GetUserNotificationsInput>
}

export type QueryGetUserRecommendationsArgs = {
  input: GetUserRecommendationsInput
}

export type QueryGetUserSummariesArgs = {
  input: UserSummariesInput
}

export type QueryGetUserTaskArgs = {
  input: GetUserTaskInput
}

export type QueryGetUserTasksArgs = {
  input: GetUserTasksInput
}

export type QueryGetUserToolsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryListAssessmentsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryMetricValueArgs = {
  input: MetricValueInput
}

export type QueryRetrieveBusinessInformationArgs = {
  businessId: Scalars['String']
}

export type QuerySearchBusinessInformationArgs = {
  businessId: Scalars['String']
}

export type QuerySearchIndustryReportArgs = {
  input: SearchIndustryReportInput
}

export type QuerySearchToolsArgs = {
  input: SearchToolsInput
}

export type QuerySearchUserSummariesArgs = {
  input: SearchUserSummariesInput
}

export type QuerySubscribeCalendlyWebhookArgs = {
  email?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryUpdateGoogleCalendarEventArgs = {
  input?: InputMaybe<UpdateGoogleCalendarEventInput>
}

export type QueryAccountingReportInput = {
  chartType: AccountingCharts
  dateRange?: InputMaybe<DateRanges>
  endDate?: InputMaybe<Scalars['String']>
  source?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['Int']>
}

export type QuoteResponse = {
  __typename?: 'QuoteResponse'
  author?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['String']>
  quote?: Maybe<Scalars['String']>
}

export type Reaction = {
  __typename?: 'Reaction'
  commentId: Scalars['String']
  createdAt: Scalars['AWSDateTime']
  id: Scalars['String']
  taskId: Scalars['String']
  type: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
  userId: Scalars['String']
}

export enum ReactionAction {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type Recommendations = {
  __typename?: 'Recommendations'
  assessmentRecommendedAt?: Maybe<Scalars['AWSDateTime']>
  assessments?: Maybe<Array<Scalars['String']>>
  playRecommendedAt?: Maybe<Scalars['AWSDateTime']>
  plays?: Maybe<Array<Scalars['String']>>
}

export type RecommendationsInput = {
  assessmentRecommendedAt?: InputMaybe<Scalars['AWSDateTime']>
  assessments?: InputMaybe<Array<Scalars['String']>>
  playRecommendedAt?: InputMaybe<Scalars['AWSDateTime']>
  plays?: InputMaybe<Array<Scalars['String']>>
}

export type RegeneratePlanAdminInput = {
  feedback: Scalars['String']
  userId: Scalars['String']
}

export type RegeneratePlanInput = {
  feedback: Scalars['String']
  goalId: Scalars['String']
}

export enum RelativeTimeRange {
  LastNDays = 'LAST_N_DAYS',
  LastNHours = 'LAST_N_HOURS',
  LastNMonths = 'LAST_N_MONTHS',
  LastNYears = 'LAST_N_YEARS',
}

export type Report = {
  __typename?: 'Report'
  columns?: Maybe<Array<Maybe<ReportColumn>>>
  endDate?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  rows?: Maybe<Array<Maybe<ReportRow>>>
  startDate?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ReportCell = {
  __typename?: 'ReportCell'
  id?: Maybe<Scalars['ID']>
  value?: Maybe<Scalars['String']>
}

export type ReportColumn = {
  __typename?: 'ReportColumn'
  id?: Maybe<Scalars['ID']>
  label?: Maybe<Scalars['String']>
}

export type ReportRow = {
  __typename?: 'ReportRow'
  cells?: Maybe<Array<Maybe<ReportCell>>>
  id?: Maybe<Scalars['ID']>
}

export type ResendUserInviteInput = {
  email: Scalars['String']
  userId: Scalars['String']
}

export type SaveAssessmentResponseInput = {
  assessmentResponse: AssessmentResponseInput
  category?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  currentStage?: InputMaybe<Scalars['ID']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  playStage?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
  type: Scalars['String']
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
  userId: Scalars['ID']
}

export type SaveBusinessGoalInput = {
  category?: InputMaybe<BusinessGoalCategory>
  description?: InputMaybe<Scalars['String']>
  expectedEndDate: Scalars['String']
  goalId: Scalars['ID']
  goalPriority?: InputMaybe<BusinessGoalPriority>
  goalTimeFrame?: InputMaybe<BusinessGoalTimeFrame>
  logo?: InputMaybe<Scalars['String']>
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name: Scalars['String']
  plan?: InputMaybe<BusinessGoalPlanInput>
  progress?: InputMaybe<Scalars['Int']>
  startDate: Scalars['String']
  status?: InputMaybe<UserTaskItemStatus>
  tenantId: Scalars['ID']
  userId: Scalars['ID']
}

export type SavePlayResponseInput = {
  businessProfileFields?: InputMaybe<PlayBusinessProfileFields>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  playId: Scalars['String']
  playType?: InputMaybe<Scalars['String']>
  recommendations?: InputMaybe<Array<Scalars['String']>>
  stepData?: InputMaybe<StepDataInput>
  stepDataIndex: Scalars['Int']
  stepIndex: Scalars['Int']
  tabId: Scalars['String']
  tenantId: Scalars['String']
}

export type SaveUserFileInput = {
  files: Array<FileDetails>
  sendNotification?: InputMaybe<Scalars['Boolean']>
}

export type SearchBusinessInformationInput = {
  EIN?: InputMaybe<Scalars['String']>
  businessId?: InputMaybe<Scalars['String']>
  businessName: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
}

export type SearchHowToDoTaskInput = {
  description?: InputMaybe<Scalars['String']>
  resources?: InputMaybe<Scalars['String']>
  taskName: Scalars['ID']
  tool?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

export type SearchIndustryReportInput = {
  keyword: Scalars['String']
}

export type SearchToolsInput = {
  categories?: InputMaybe<Array<Scalars['String']>>
  featured?: InputMaybe<Scalars['Boolean']>
  freeFormSearchQuery?: InputMaybe<Scalars['String']>
  inUserToolKit?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

export type SearchToolsResponse = {
  __typename?: 'SearchToolsResponse'
  data: Array<Tool>
  numberOfPages?: Maybe<Scalars['Int']>
}

export type SearchUserSummariesInput = {
  dateRange?: InputMaybe<UserSummaryDateRange>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  searchTerm?: InputMaybe<Scalars['String']>
}

export type SendMessageToBotInput = {
  sessionId?: InputMaybe<Scalars['String']>
  skip?: InputMaybe<Scalars['Boolean']>
  userMessage: Scalars['String']
  userMessageType?: InputMaybe<ChatBotMessageTypes>
}

export type SendMessageToBotResponse = {
  __typename?: 'SendMessageToBotResponse'
  flowType?: Maybe<Scalars['String']>
  isFinished?: Maybe<Scalars['Boolean']>
  nextFlow?: Maybe<Scalars['String']>
  progress?: Maybe<Scalars['Int']>
  responses?: Maybe<Array<Scalars['String']>>
}

export type SetUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type SetUserAvailabilityObj = {
  __typename?: 'SetUserAvailabilityObj'
  availabilityHours?: Maybe<AvailabilityHoursObj>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type SetUserAvailabilityResponse = {
  __typename?: 'SetUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type SetUserMeetingInput = {
  advisorName?: InputMaybe<Scalars['String']>
  attendees?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  endTime: Scalars['String']
  ignoreConflicts?: InputMaybe<Scalars['Boolean']>
  location?: InputMaybe<Scalars['String']>
  meetingLocationTool: Scalars['String']
  organizerName?: InputMaybe<Scalars['String']>
  participantId: Scalars['String']
  participantName?: InputMaybe<Scalars['String']>
  role: Array<Scalars['String']>
  startTime: Scalars['String']
  teamMemberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tenantId: Scalars['String']
  timezone: Scalars['String']
  title: Scalars['String']
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type SlotInput = {
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type SlotObj = {
  __typename?: 'SlotObj'
  isAvailable?: Maybe<Scalars['Boolean']>
  slotEndTime?: Maybe<Scalars['String']>
  slotStartTime?: Maybe<Scalars['String']>
}

export type SlotOutput = {
  __typename?: 'SlotOutput'
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type StepBusinessProfileFields = {
  __typename?: 'StepBusinessProfileFields'
  logo?: Maybe<FileStructure>
}

export type StepBusinessProfileFieldsInput = {
  logo?: InputMaybe<FileStructureInput>
}

export type StepData = {
  __typename?: 'StepData'
  businessProfileFields?: Maybe<StepBusinessProfileFields>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  isMeetingScheduled?: Maybe<Scalars['Boolean']>
  label?: Maybe<Array<Scalars['String']>>
  recommendations?: Maybe<Array<Scalars['String']>>
  value?: Maybe<Array<Scalars['String']>>
}

export type StepDataInput = {
  businessProfileFields?: InputMaybe<StepBusinessProfileFieldsInput>
  completedAt?: InputMaybe<Scalars['AWSDateTime']>
  id?: InputMaybe<Scalars['String']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  isMeetingScheduled?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Array<Scalars['String']>>
  recommendations?: InputMaybe<Array<Scalars['String']>>
  value?: InputMaybe<Array<Scalars['String']>>
}

export type StepResponse = {
  __typename?: 'StepResponse'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  stepData?: Maybe<Array<StepData>>
  stepTitle?: Maybe<Scalars['String']>
}

export type StrategyHistory = {
  __typename?: 'StrategyHistory'
  createdAt: Scalars['AWSDateTime']
  feedback?: Maybe<Scalars['String']>
  status: StrategyStatus
  strategy: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
}

export type StrategyHistoryInput = {
  createdAt: Scalars['AWSDateTime']
  status: StrategyStatus
  strategy: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
}

export enum StrategyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type SubscribeGoogleCalendarWebhookResponse = {
  __typename?: 'SubscribeGoogleCalendarWebhookResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  onTaskProgressUpdated?: Maybe<TaskResult>
}

export type SubscriptionOnTaskProgressUpdatedArgs = {
  id: Scalars['ID']
}

export type SystemSetting = {
  __typename?: 'SystemSetting'
  category?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type TabResponse = {
  __typename?: 'TabResponse'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  isCompleted?: Maybe<Scalars['Boolean']>
  stepResponse?: Maybe<Array<StepResponse>>
  tabName?: Maybe<Scalars['String']>
}

export type Task = {
  __typename?: 'Task'
  assignedByUserId?: Maybe<Scalars['String']>
  assignedToUserId?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  playType?: Maybe<Scalars['String']>
  prerequisites?: Maybe<TaskPrerequisites>
  rank?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type TaskComment = {
  __typename?: 'TaskComment'
  content: Scalars['String']
  createdAt: Scalars['AWSDateTime']
  createdBy: UserProfile
  createdById: Scalars['String']
  files?: Maybe<Array<UserFile>>
  id: Scalars['String']
  parentId?: Maybe<Scalars['String']>
  reactions?: Maybe<Array<Reaction>>
  replies?: Maybe<Array<TaskComment>>
  tags?: Maybe<Array<Scalars['String']>>
  taskId: Scalars['String']
  updatedAt: Scalars['AWSDateTime']
}

export type TaskCompletionBaseCondition = {
  __typename?: 'TaskCompletionBaseCondition'
  conditions?: Maybe<Array<BaseCondition>>
  conjunction?: Maybe<TaskCompletionConjunction>
}

export enum TaskCompletionConjunction {
  And = 'AND',
  Or = 'OR',
}

export type TaskCompletionSchema = GroupTaskCompletionCondition | TaskCompletionBaseCondition

export type TaskResult = {
  __typename?: 'TaskResult'
  id?: Maybe<Scalars['ID']>
  payload?: Maybe<Scalars['AWSJSON']>
  status?: Maybe<Scalars['ID']>
}

export enum TaskResultStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

export enum TaskType {
  Assessment = 'ASSESSMENT',
  Play = 'PLAY',
}

export type Tenant = {
  __typename?: 'Tenant'
  adminId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type Term = {
  __typename?: 'Term'
  replacement: Scalars['String']
  term: Scalars['String']
}

export type TimePeriod = {
  day?: InputMaybe<Scalars['Int']>
  month: Scalars['Int']
  year: Scalars['Int']
}

export type TimeRangeInput = {
  endDate?: InputMaybe<Scalars['AWSDateTime']>
  n?: InputMaybe<Scalars['Int']>
  rangeType?: InputMaybe<TimeRangeType>
  relative?: InputMaybe<RelativeTimeRange>
  startDate?: InputMaybe<Scalars['AWSDateTime']>
}

export enum TimeRangeType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export type TimeRangeValueInput = {
  asOfDate: Scalars['AWSDate']
  granularity?: InputMaybe<MetricGranularity>
}

export type TimeSeriesResponse = {
  __typename?: 'TimeSeriesResponse'
  labels?: Maybe<Array<Scalars['String']>>
  values?: Maybe<Array<Scalars['Float']>>
}

export type TimeSlotsInput = {
  label?: InputMaybe<Scalars['String']>
  slots?: InputMaybe<Array<InputMaybe<SlotInput>>>
  value?: InputMaybe<Scalars['String']>
}

export type TimeSlotsObj = {
  __typename?: 'TimeSlotsObj'
  label?: Maybe<Scalars['String']>
  slots?: Maybe<Array<Maybe<SlotOutput>>>
  value?: Maybe<Scalars['String']>
}

export type Tool = {
  __typename?: 'Tool'
  authType?: Maybe<ToolAuthType>
  authorizationQueryParameters?: Maybe<Array<KeyValuePair>>
  authorizationUri?: Maybe<Scalars['String']>
  categories?: Maybe<Array<ToolCategory>>
  category?: Maybe<Scalars['String']>
  clientId?: Maybe<Scalars['String']>
  clientSecret?: Maybe<Scalars['String']>
  connectionFormImageUrl?: Maybe<Scalars['String']>
  connectionStatus?: Maybe<Scalars['String']>
  connectorName?: Maybe<Scalars['String']>
  cyclrToolId?: Maybe<Scalars['String']>
  dataScope?: Maybe<ToolDataScope>
  dataTypes?: Maybe<Array<ToolDataTypes>>
  description?: Maybe<Scalars['String']>
  diagnosticSupported?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  imageUrl?: Maybe<Scalars['String']>
  isBookmarked?: Maybe<Scalars['Boolean']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  keyWords?: Maybe<Array<Scalars['String']>>
  longDescription?: Maybe<Scalars['String']>
  oAuthUrl?: Maybe<Scalars['String']>
  options?: Maybe<ToolConnectionOptions>
  pricingPageUrl?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  relatedPlays?: Maybe<Array<Maybe<Scalars['String']>>>
  scopes?: Maybe<Array<Scalars['String']>>
  subCategory?: Maybe<Scalars['String']>
  supportLevel?: Maybe<ToolSupportLevel>
  tags?: Maybe<Array<Scalars['String']>>
  tokenUrl?: Maybe<Scalars['String']>
  toolDisplayName?: Maybe<Scalars['String']>
  toolHelpText?: Maybe<Scalars['String']>
  toolName?: Maybe<Scalars['String']>
  toolType?: Maybe<ToolType>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
  visible?: Maybe<Scalars['Boolean']>
}

export enum ToolAuthType {
  ApiKey = 'API_KEY',
  Oauth = 'OAUTH',
  UsernameAndPassword = 'USERNAME_AND_PASSWORD',
}

export type ToolCategory = {
  __typename?: 'ToolCategory'
  category: Scalars['String']
  subCategory: Scalars['String']
}

export type ToolCategoryInput = {
  category: Scalars['String']
  subCategory: Scalars['String']
}

export type ToolConnectionForm = {
  __typename?: 'ToolConnectionForm'
  description: Scalars['String']
  inputProperties: Array<ToolConnectionFormInputProperty>
}

export type ToolConnectionFormInput = {
  description: Scalars['String']
  inputProperties: Array<ToolConnectionFormInputPropertyInput>
}

export type ToolConnectionFormInputProperty = {
  __typename?: 'ToolConnectionFormInputProperty'
  displayName: Scalars['String']
  name: Scalars['String']
  placeholder?: Maybe<Scalars['String']>
  type: Scalars['String']
  validationRules: Array<Scalars['String']>
}

export type ToolConnectionFormInputPropertyInput = {
  displayName: Scalars['String']
  name: Scalars['String']
  placeholder?: InputMaybe<Scalars['String']>
  type: Scalars['String']
  validationRules: Array<Scalars['String']>
}

export type ToolConnectionOptions = {
  __typename?: 'ToolConnectionOptions'
  connectionForm?: Maybe<ToolConnectionForm>
}

export type ToolConnectionOptionsInput = {
  connectionForm?: InputMaybe<ToolConnectionFormInput>
}

export enum ToolConnectionStatus {
  Connected = 'CONNECTED',
  ConnectionError = 'CONNECTION_ERROR',
  Disconnected = 'DISCONNECTED',
  Reconnected = 'RECONNECTED',
}

export type ToolConnectionSuccessInput = {
  connectorName: Scalars['String']
  playId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export enum ToolDataScope {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION',
}

export enum ToolDataTypes {
  Bookkeeping = 'Bookkeeping',
  CustomerRelationshipManagement = 'CustomerRelationshipManagement',
  Marketing = 'Marketing',
  Sales = 'Sales',
}

export type ToolImageFileInput = {
  base64Data: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  type: Scalars['String']
}

export enum ToolSupportLevel {
  Connect = 'CONNECT',
  DataRetrieval = 'DATA_RETRIEVAL',
  Open = 'OPEN',
}

export enum ToolType {
  Calendar = 'CALENDAR',
}

export type TopActiveClient = {
  __typename?: 'TopActiveClient'
  businessName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  industry?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  logo?: Maybe<UserFile>
  score?: Maybe<Scalars['Float']>
  tenantId?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  totalTime?: Maybe<Scalars['Float']>
}

export type UpdateBusinessGoalInput = {
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId: Scalars['ID']
  goalPriority?: InputMaybe<BusinessGoalPriority>
  name?: InputMaybe<Scalars['String']>
  progress?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  strategyHistory?: InputMaybe<Array<StrategyHistoryInput>>
  suggestedStatus?: InputMaybe<UserTaskSuggestedItemStatus>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}

export type UpdateBusinessGoalMilestoneInput = {
  descriptions?: InputMaybe<Scalars['String']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  isFumbled?: InputMaybe<Scalars['Boolean']>
  milestoneId: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  planId: Scalars['ID']
  riskAndChallenges?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  timeline?: InputMaybe<Scalars['String']>
}

export type UpdateBusinessProfileInput = {
  EIN?: InputMaybe<Scalars['String']>
  POS?: InputMaybe<Scalars['String']>
  POSOptionId?: InputMaybe<Scalars['String']>
  accountingMethod?: InputMaybe<Scalars['String']>
  accountingMethodOptionId?: InputMaybe<Scalars['String']>
  additionalContext?: InputMaybe<Scalars['AWSJSON']>
  additionalInfo?: InputMaybe<Scalars['String']>
  address?: InputMaybe<AddressInput>
  avatar?: InputMaybe<Scalars['String']>
  bookKeepingTool?: InputMaybe<Scalars['String']>
  bookKeepingToolOptionId?: InputMaybe<Scalars['String']>
  budget?: InputMaybe<Scalars['Float']>
  businessAddress?: InputMaybe<BusinessAddressInput>
  businessBankAccount?: InputMaybe<Scalars['Boolean']>
  businessClassification?: InputMaybe<Scalars['String']>
  businessClassificationOptionId?: InputMaybe<Scalars['String']>
  businessDescription?: InputMaybe<Scalars['String']>
  businessEmail?: InputMaybe<Scalars['String']>
  businessLocations?: InputMaybe<Array<Scalars['String']>>
  businessLocationsOptionIds?: InputMaybe<Array<Scalars['String']>>
  businessMetricsViewPermission?: InputMaybe<BusinessMetricsViewPermissionState>
  businessName?: InputMaybe<Scalars['String']>
  businessNature?: InputMaybe<Scalars['String']>
  businessNatureOptionId?: InputMaybe<Scalars['String']>
  businessPlan?: InputMaybe<Scalars['String']>
  businessStage?: InputMaybe<Scalars['String']>
  businessStageOptionId?: InputMaybe<Scalars['String']>
  businessVision?: InputMaybe<Scalars['String']>
  challenges?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['String']>
  contractors?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  crmToolIds?: InputMaybe<Array<Scalars['String']>>
  dba?: InputMaybe<Scalars['String']>
  dbaNames?: InputMaybe<Array<Scalars['String']>>
  debtFinancing?: InputMaybe<Scalars['String']>
  domainName?: InputMaybe<Scalars['String']>
  education?: InputMaybe<Scalars['String']>
  employeeBenefit?: InputMaybe<Scalars['String']>
  exitPlan?: InputMaybe<Scalars['String']>
  exitPlanOptionId?: InputMaybe<Scalars['String']>
  expenses?: InputMaybe<Scalars['Float']>
  facebook?: InputMaybe<Scalars['String']>
  financeTrackingToolIds?: InputMaybe<Array<Scalars['String']>>
  financialGrowth?: InputMaybe<Scalars['String']>
  foodSubIndustry?: InputMaybe<Array<Scalars['String']>>
  foodSubIndustryOptionIds?: InputMaybe<Array<Scalars['String']>>
  fullTimeEmployees?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  googleBusinessProfilePlays?: InputMaybe<Array<Scalars['String']>>
  googleBusinessProfilePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  googleWorkspacePlays?: InputMaybe<Array<Scalars['String']>>
  googleWorkspacePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  grossRevenueGoalForThisYear?: InputMaybe<Scalars['String']>
  hasAvatar?: InputMaybe<Scalars['Boolean']>
  hasBookKeepingTool?: InputMaybe<Scalars['String']>
  hasBookKeepingToolOptionId?: InputMaybe<Scalars['String']>
  hasBudget?: InputMaybe<Scalars['String']>
  hasBudgetOptionId?: InputMaybe<Scalars['String']>
  hasBusinessEmail?: InputMaybe<Scalars['String']>
  hasBusinessEmailOptionId?: InputMaybe<Scalars['String']>
  hasBusinessEmployees?: InputMaybe<Scalars['String']>
  hasBusinessEmployeesOptionId?: InputMaybe<Scalars['String']>
  hasBusinessPhoneNumber?: InputMaybe<Scalars['String']>
  hasBusinessPhoneNumberOptionId?: InputMaybe<Scalars['String']>
  hasDomainName?: InputMaybe<Scalars['String']>
  hasDomainNameOptionId?: InputMaybe<Scalars['String']>
  hasEIN?: InputMaybe<Scalars['Boolean']>
  hasEsignatureTool?: InputMaybe<Scalars['String']>
  hasEsignatureToolOptionId?: InputMaybe<Scalars['String']>
  hasFacebook?: InputMaybe<Scalars['Boolean']>
  hasGoogleBusinessProfile?: InputMaybe<Scalars['String']>
  hasGoogleBusinessProfileOptionId?: InputMaybe<Scalars['String']>
  hasGoogleChrome?: InputMaybe<Scalars['String']>
  hasGoogleChromeOptionId?: InputMaybe<Scalars['String']>
  hasGoogleWorkspace?: InputMaybe<Scalars['String']>
  hasGoogleWorkspaceOptionId?: InputMaybe<Scalars['String']>
  hasInstagram?: InputMaybe<Scalars['Boolean']>
  hasLinkedin?: InputMaybe<Scalars['Boolean']>
  hasLogo?: InputMaybe<Scalars['String']>
  hasLogoOptionId?: InputMaybe<Scalars['String']>
  hasLogoTrademarked?: InputMaybe<Scalars['String']>
  hasLogoTrademarkedOptionId?: InputMaybe<Scalars['String']>
  hasMoreLocations?: InputMaybe<Scalars['String']>
  hasMoreLocationsOptionId?: InputMaybe<Scalars['String']>
  hasPOS?: InputMaybe<Scalars['String']>
  hasPOSOptionId?: InputMaybe<Scalars['String']>
  hasPaymentProcessor?: InputMaybe<Scalars['String']>
  hasPaymentProcessorOptionId?: InputMaybe<Scalars['String']>
  hasPricingStrategies?: InputMaybe<Scalars['String']>
  hasPricingStrategiesOptionId?: InputMaybe<Scalars['String']>
  hasQuickBooks?: InputMaybe<Scalars['String']>
  hasQuickBooksOptionId?: InputMaybe<Scalars['String']>
  hasRegisterBusinessStructure?: InputMaybe<Scalars['Boolean']>
  hasTikTok?: InputMaybe<Scalars['Boolean']>
  hasTwitter?: InputMaybe<Scalars['Boolean']>
  hasWebsite?: InputMaybe<Scalars['Boolean']>
  hasYoutube?: InputMaybe<Scalars['Boolean']>
  haveStartedGeneratingSales?: InputMaybe<Scalars['Boolean']>
  hoursSpent?: InputMaybe<Scalars['String']>
  hoursSpentOptionId?: InputMaybe<Scalars['String']>
  hoursSpentPerMonth?: InputMaybe<Scalars['Int']>
  hoursSpentPerWeek?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  instagram?: InputMaybe<Scalars['String']>
  isOnboarded?: InputMaybe<Scalars['Boolean']>
  isUserCreation?: InputMaybe<Scalars['Boolean']>
  jobsCreated?: InputMaybe<Scalars['String']>
  lastUpdatedBusinessPlan?: InputMaybe<Scalars['AWSDateTime']>
  lastYearRevenue?: InputMaybe<Scalars['String']>
  launchDate?: InputMaybe<Scalars['AWSDateTime']>
  learnPricingStrategies?: InputMaybe<Scalars['String']>
  learnPricingStrategiesOptionId?: InputMaybe<Scalars['String']>
  linkedin?: InputMaybe<Scalars['String']>
  loginFrequency?: InputMaybe<Scalars['String']>
  logo?: InputMaybe<Scalars['String']>
  minorityOwned?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  motivation?: InputMaybe<Scalars['String']>
  naicsCode?: InputMaybe<Scalars['String']>
  newCustomer?: InputMaybe<Scalars['String']>
  noOfBusinessStarted?: InputMaybe<Scalars['String']>
  numberOfBusinessBankAccounts?: InputMaybe<Scalars['String']>
  onboardedAt?: InputMaybe<Scalars['String']>
  otherExitPlan?: InputMaybe<Scalars['String']>
  otherGoals?: InputMaybe<Array<Scalars['String']>>
  otherGoalsOptionIds?: InputMaybe<Array<Scalars['String']>>
  otherRegisteredBusinessStructure?: InputMaybe<Scalars['String']>
  otherWhereDoYouDoBusiness?: InputMaybe<Scalars['String']>
  partTimeEmployees?: InputMaybe<Scalars['String']>
  paymentAcceptance?: InputMaybe<Scalars['String']>
  paymentAcceptanceOptionId?: InputMaybe<Scalars['String']>
  paymentAcceptancePlan?: InputMaybe<Scalars['String']>
  paymentAcceptancePlanOptionId?: InputMaybe<Scalars['String']>
  paymentApps?: InputMaybe<Array<Scalars['String']>>
  paymentAppsOptionIds?: InputMaybe<Array<Scalars['String']>>
  paymentProcessor?: InputMaybe<Scalars['String']>
  paymentProcessorOptionId?: InputMaybe<Scalars['String']>
  paymentTypes?: InputMaybe<Array<Scalars['String']>>
  paymentTypesOptionIds?: InputMaybe<Array<Scalars['String']>>
  personalGoals?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  physicalLocation?: InputMaybe<Scalars['String']>
  pricingStrategies?: InputMaybe<Array<Scalars['String']>>
  pricingStrategiesOptionIds?: InputMaybe<Array<Scalars['String']>>
  productSale?: InputMaybe<Scalars['String']>
  productsAndServices?: InputMaybe<Array<Scalars['String']>>
  productsAndServicesOptionIds?: InputMaybe<Array<Scalars['String']>>
  quickBookPlays?: InputMaybe<Array<Scalars['String']>>
  quickBookPlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  race?: InputMaybe<Scalars['String']>
  registerBusinessStructure?: InputMaybe<Scalars['String']>
  registerBusinessStructureOptionId?: InputMaybe<Scalars['String']>
  revenue?: InputMaybe<Scalars['String']>
  revenueStatus?: InputMaybe<Scalars['String']>
  revenueStatusOptionId?: InputMaybe<Scalars['String']>
  saleGoal?: InputMaybe<Scalars['String']>
  sbaCertified?: InputMaybe<Scalars['String']>
  seasonalityDetails?: InputMaybe<Scalars['String']>
  serviceSale?: InputMaybe<Scalars['String']>
  servicesSubIndustry?: InputMaybe<Scalars['String']>
  servicesSubIndustryOptionId?: InputMaybe<Scalars['String']>
  squarePlays?: InputMaybe<Array<Scalars['String']>>
  squarePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  stripePlays?: InputMaybe<Array<Scalars['String']>>
  stripePlaysOptionIds?: InputMaybe<Array<Scalars['String']>>
  strongBusinessAreas?: InputMaybe<Array<Scalars['String']>>
  strongBusinessAreasOptionIds?: InputMaybe<Array<Scalars['String']>>
  supportAndHelp?: InputMaybe<Array<Scalars['String']>>
  supportAndHelpOptionIds?: InputMaybe<Array<Scalars['String']>>
  targetAudience?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  thisYearRevenue?: InputMaybe<Scalars['String']>
  tiktok?: InputMaybe<Scalars['String']>
  topGoal?: InputMaybe<Scalars['String']>
  topGoalOptionId?: InputMaybe<Scalars['String']>
  totalEmployees?: InputMaybe<Scalars['String']>
  twitter?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
  usePOS?: InputMaybe<Scalars['String']>
  usePOSOptionId?: InputMaybe<Scalars['String']>
  usePaymentProcessor?: InputMaybe<Scalars['String']>
  usePaymentProcessorOptionId?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  veteranOwned?: InputMaybe<Scalars['String']>
  volunteers?: InputMaybe<Scalars['String']>
  wages?: InputMaybe<Scalars['String']>
  whereDoYouDoBusiness?: InputMaybe<Array<Scalars['String']>>
  whereDoYouDoBusinessOptionIds?: InputMaybe<Array<Scalars['String']>>
  year?: InputMaybe<Scalars['String']>
  yearEstablished?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
  youtube?: InputMaybe<Scalars['String']>
}

export type UpdateGoalPlanInput = {
  expectedEndDate?: InputMaybe<Scalars['String']>
  goalId: Scalars['ID']
  isUndismissedFumbledState?: InputMaybe<Scalars['Boolean']>
  isUndismissedMicroCelebrationState?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  pauseDate?: InputMaybe<Scalars['String']>
  pauseDuration?: InputMaybe<Scalars['Int']>
  planId: Scalars['ID']
  progress?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['String']>
  state?: InputMaybe<BusinessGoalPlanState>
  timeline?: InputMaybe<Scalars['String']>
}

export type UpdateMyProfileInput = {
  advisors?: InputMaybe<Array<Scalars['String']>>
  attestationStatus?: InputMaybe<Scalars['String']>
  bio?: InputMaybe<Scalars['String']>
  calendarEventViewPreference?: InputMaybe<CalendarEventViewPreference>
  calendlyEventsUrl?: InputMaybe<Scalars['String']>
  companyAdvisor?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['Int']>
  defaultVideoLinkDescription?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['AWSEmail']>
  firstName?: InputMaybe<Scalars['String']>
  interimTimezone?: InputMaybe<Scalars['String']>
  isConsentGivenToAdvisor?: InputMaybe<Scalars['Boolean']>
  isJoinedBetaProgram?: InputMaybe<Scalars['Boolean']>
  isNewUser?: InputMaybe<Scalars['Boolean']>
  isSmsEnabled?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  mobileContactNumber?: InputMaybe<Scalars['AWSPhone']>
  name?: InputMaybe<Scalars['String']>
  onboardingStatus?: InputMaybe<OnboardingStatus>
  owners?: InputMaybe<Array<Scalars['String']>>
  quoteViewDate?: InputMaybe<Scalars['String']>
  schedulingUrl?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  streamAccessToken?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  websiteURL?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
}

export type UpdateOwnerInput = {
  ownerExperience?: InputMaybe<Scalars['String']>
  selectedTools?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
}

export type UpdateTaskCommentInput = {
  commentId: Scalars['String']
  content: Scalars['String']
  fileIds?: InputMaybe<Array<Scalars['String']>>
  tags?: InputMaybe<Array<Scalars['String']>>
  taskId: Scalars['String']
}

export type UpdateTaskReactionInput = {
  action: ReactionAction
  commentId: Scalars['String']
  reactionType: Scalars['String']
  taskId: Scalars['String']
}

export type UpdateToolInput = {
  authType?: InputMaybe<ToolAuthType>
  authorizationQueryParameters?: InputMaybe<Array<KeyValuePairInput>>
  authorizationUri?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Array<ToolCategoryInput>>
  category?: InputMaybe<Scalars['String']>
  clientId?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
  connectionFormImageUrl?: InputMaybe<Scalars['String']>
  connectionStatus?: InputMaybe<Scalars['String']>
  connectorName?: InputMaybe<Scalars['String']>
  cyclrToolId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  diagnosticSupported?: InputMaybe<Scalars['Boolean']>
  featured?: InputMaybe<Scalars['Boolean']>
  group?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  imageFile?: InputMaybe<ToolImageFileInput>
  imageUrl?: InputMaybe<Scalars['String']>
  isBookmarked?: InputMaybe<Scalars['Boolean']>
  isConnected?: InputMaybe<Scalars['Boolean']>
  isRecommended?: InputMaybe<Scalars['Boolean']>
  isSelected?: InputMaybe<Scalars['Boolean']>
  keyWords?: InputMaybe<Array<Scalars['String']>>
  longDescription?: InputMaybe<Scalars['String']>
  options?: InputMaybe<ToolConnectionOptionsInput>
  pricingPageUrl?: InputMaybe<Scalars['String']>
  recommendedBy?: InputMaybe<Scalars['String']>
  relatedPlays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  scopes?: InputMaybe<Array<Scalars['String']>>
  supportLevel?: InputMaybe<ToolSupportLevel>
  tags?: InputMaybe<Array<Scalars['String']>>
  tokenUrl?: InputMaybe<Scalars['String']>
  toolDisplayName?: InputMaybe<Scalars['String']>
  toolHelpText?: InputMaybe<Scalars['String']>
  toolName?: InputMaybe<Scalars['String']>
  toolType?: InputMaybe<ToolType>
  toolUrl?: InputMaybe<Scalars['String']>
  userToolsImageUrl?: InputMaybe<Scalars['String']>
  visible?: InputMaybe<Scalars['Boolean']>
}

export type UpdateUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type UpdateUserAvailabilityResponse = {
  __typename?: 'UpdateUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEvent = {
  __typename?: 'UpdateUserCalendarEvent'
  attendees?: Maybe<Array<Maybe<AttendeesType>>>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEventInput = {
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  comment?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['String']>
  tackleId: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type UpdateUserCalendarEventResponse = {
  __typename?: 'UpdateUserCalendarEventResponse'
  data?: Maybe<UpdateUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserNotificationsInput = {
  id?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  updateAll?: InputMaybe<Scalars['Boolean']>
}

export type UpdateUserPasswordInput = {
  password?: InputMaybe<Scalars['String']>
}

export type UpdateUserTaskInput = {
  assigneeId?: InputMaybe<Scalars['ID']>
  category?: InputMaybe<BusinessGoalCategory>
  estimatedEffortInMinutes?: InputMaybe<Scalars['Int']>
  expectedEndDate?: InputMaybe<Scalars['String']>
  files?: InputMaybe<Array<UserFileInput>>
  goalId?: InputMaybe<Scalars['String']>
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  isCarryOver?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  planId?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<UserTaskItemStatus>
  suggestedStatus?: InputMaybe<UserTaskSuggestedItemStatus>
  taskId: Scalars['ID']
  taskPriority?: InputMaybe<BusinessGoalPriority>
  tenantId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
  userInputFields?: InputMaybe<Array<UserInputFieldInput>>
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  id?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionId?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['AWSJSON']>
  value?: Maybe<Scalars['String']>
}

export type UserAssessmentResponse = {
  __typename?: 'UserAssessmentResponse'
  data?: Maybe<AssessmentResponse>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserAvailability = {
  __typename?: 'UserAvailability'
  date: Scalars['String']
  slots: Array<SlotObj>
}

export type UserFile = {
  __typename?: 'UserFile'
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type UserFileInput = {
  createdAt?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type UserInputField = {
  __typename?: 'UserInputField'
  conditionalField?: Maybe<ConditionalField>
  destinationResource?: Maybe<DestinationResource>
  fieldType: FieldType
  label: Scalars['String']
  multiple?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  options?: Maybe<Array<Option>>
  optionsSource?: Maybe<Scalars['String']>
  placeholder?: Maybe<Scalars['String']>
  validation?: Maybe<Validation>
  value?: Maybe<Scalars['String']>
}

export type UserInputFieldInput = {
  conditionalField?: InputMaybe<ConditionalFieldInput>
  destinationResource?: InputMaybe<DestinationResourceInput>
  fieldType: FieldType
  label: Scalars['String']
  multiple?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  options?: InputMaybe<Array<OptionInput>>
  optionsSource?: InputMaybe<Scalars['String']>
  placeholder?: InputMaybe<Scalars['String']>
  validation?: InputMaybe<ValidationInput>
  value?: InputMaybe<Scalars['String']>
}

export type UserIntegrations = {
  __typename?: 'UserIntegrations'
  cyclrToolId?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  lastConnectedAt?: Maybe<Scalars['AWSDateTime']>
  name?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
}

export type UserIntegrationsResponse = {
  __typename?: 'UserIntegrationsResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserMeetingData = {
  __typename?: 'UserMeetingData'
  advisorName?: Maybe<Scalars['String']>
  attendees?: Maybe<Array<CalendarEventAttendee>>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  organizerName?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  participantId?: Maybe<Scalars['String']>
  participantName?: Maybe<Scalars['String']>
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UserNotification = {
  __typename?: 'UserNotification'
  businessName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['String']
  lastName?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  sender?: Maybe<Scalars['AWSJSON']>
  senderId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type UserNotificationData = {
  __typename?: 'UserNotificationData'
  lastEvaluatedKey?: Maybe<Scalars['String']>
  notifications?: Maybe<Array<Maybe<UserNotification>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type UserProfile = {
  __typename?: 'UserProfile'
  activatedAt?: Maybe<Scalars['String']>
  algoliaSearchKey?: Maybe<Scalars['String']>
  attestationStatus?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  calendarEventViewPreference?: Maybe<CalendarEventViewPreference>
  calendlyEventsUrl?: Maybe<Scalars['String']>
  companyAdvisor?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['Int']>
  createdAt: Scalars['AWSDateTime']
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  firstName?: Maybe<Scalars['String']>
  firstSigninDate?: Maybe<Scalars['String']>
  googleCalendarWebhookMetaData?: Maybe<GoogleCalendarWebhookMetaData>
  id: Scalars['ID']
  interimTimezone?: Maybe<Scalars['String']>
  isConsentGivenToAdvisor?: Maybe<Scalars['Boolean']>
  isJoinedBetaProgram?: Maybe<Scalars['Boolean']>
  isNewUser?: Maybe<Scalars['Boolean']>
  isSmsEnabled?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['AWSPhone']>
  name?: Maybe<Scalars['String']>
  onboardingStatus?: Maybe<OnboardingStatus>
  organizationName?: Maybe<Scalars['String']>
  ownerExperience?: Maybe<Scalars['String']>
  quoteViewDate?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  schedulingUrl?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  streamAccessToken?: Maybe<Scalars['String']>
  submissionEndDay?: Maybe<Scalars['Int']>
  submissionReminderDay?: Maybe<Scalars['Int']>
  submissionReportDay?: Maybe<Scalars['Int']>
  submissionStartDay?: Maybe<Scalars['Int']>
  tempPassword?: Maybe<Scalars['String']>
  tenantId?: Maybe<Array<Scalars['String']>>
  timezone?: Maybe<Scalars['String']>
  updatedAt: Scalars['AWSDateTime']
  websiteURL?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type UserRecommendationsResponse = {
  __typename?: 'UserRecommendationsResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserResponse = {
  __typename?: 'UserResponse'
  data?: Maybe<UserAnswer>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserSummariesInput = {
  dateRange: UserSummaryDateRange
  lastEvaluatedKey?: InputMaybe<Scalars['ID']>
  sortField?: InputMaybe<UserSummarySortField>
  sortOrder?: InputMaybe<SortOrder>
  tenantId?: InputMaybe<Scalars['ID']>
}

export type UserSummary = {
  __typename?: 'UserSummary'
  firstName?: Maybe<Scalars['String']>
  fumbleCount?: Maybe<Scalars['Int']>
  lastLoginDate?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  nextMeetingDate?: Maybe<Scalars['String']>
  touchdownCount?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['ID']>
}

export enum UserSummaryDateRange {
  MonthToDate = 'MONTH_TO_DATE',
  YearToDate = 'YEAR_TO_DATE',
}

export type UserSummaryResponse = {
  __typename?: 'UserSummaryResponse'
  items?: Maybe<Array<UserSummary>>
  lastEvaluatedKey?: Maybe<Scalars['ID']>
  totalRecordsCount?: Maybe<Scalars['Int']>
}

export enum UserSummarySortField {
  FirstName = 'FIRST_NAME',
  FumbleCount = 'FUMBLE_COUNT',
  LastLoginDate = 'LAST_LOGIN_DATE',
  LastName = 'LAST_NAME',
  NextMeetingDate = 'NEXT_MEETING_DATE',
  TouchdownCount = 'TOUCHDOWN_COUNT',
}

export type UserTask = {
  __typename?: 'UserTask'
  assigneeId?: Maybe<Scalars['ID']>
  businessAdvisorsData?: Maybe<Array<Maybe<BusinessAdvisorsData>>>
  businessAvatar?: Maybe<Scalars['String']>
  businessHoursSpentPerDay?: Maybe<Scalars['Int']>
  category?: Maybe<BusinessGoalCategory>
  comments?: Maybe<Array<TaskComment>>
  createdBy?: Maybe<Scalars['String']>
  createdById?: Maybe<Scalars['ID']>
  estimatedEffortInMinutes?: Maybe<Scalars['Int']>
  expectedEndDate?: Maybe<Scalars['String']>
  files?: Maybe<Array<UserFile>>
  filter?: Maybe<UserTasksFilter>
  goalEndDate?: Maybe<Scalars['String']>
  goalId?: Maybe<Scalars['String']>
  howToDo?: Maybe<Scalars['String']>
  howToLinks?: Maybe<Array<Maybe<UserTaskHowTo>>>
  instructionForTask?: Maybe<Scalars['String']>
  isCarryOver?: Maybe<Scalars['Boolean']>
  isOverDue?: Maybe<Scalars['Boolean']>
  kpis?: Maybe<Scalars['String']>
  milestoneId?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  planExpectedEndDate?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['ID']>
  planName?: Maybe<Scalars['String']>
  planStartDate?: Maybe<Scalars['String']>
  resources?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  source?: Maybe<UserTaskSource>
  startDate?: Maybe<Scalars['String']>
  status?: Maybe<UserTaskItemStatus>
  suggestedStatus?: Maybe<UserTaskSuggestedItemStatus>
  taskCompletion?: Maybe<TaskCompletionSchema>
  taskId?: Maybe<Scalars['ID']>
  taskPriority?: Maybe<BusinessGoalPriority>
  tenantId?: Maybe<Scalars['ID']>
  timeline?: Maybe<Scalars['String']>
  tool?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['ID']>
  userInputFields?: Maybe<Array<UserInputField>>
  why?: Maybe<Scalars['String']>
}

export type UserTaskHowTo = {
  __typename?: 'UserTaskHowTo'
  howToId: Scalars['ID']
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UserTaskHowToInput = {
  howToId: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type UserTaskHowToResponse = {
  __typename?: 'UserTaskHowToResponse'
  text: Scalars['String']
  videoLinks: Array<Maybe<Scalars['String']>>
}

export type UserTaskInput = {
  estimatedEffortInMinutes: Scalars['Int']
  expectedEndDate: Scalars['String']
  howToDo?: InputMaybe<Scalars['String']>
  howToLinks?: InputMaybe<Array<InputMaybe<UserTaskHowToInput>>>
  instructionForTask?: InputMaybe<Scalars['String']>
  kpis?: InputMaybe<Scalars['String']>
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
  planId?: InputMaybe<Scalars['ID']>
  resources?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Scalars['String']>
  source?: InputMaybe<UserTaskSource>
  startDate: Scalars['String']
  status?: InputMaybe<UserTaskItemStatus>
  taskId: Scalars['ID']
  tenantId?: InputMaybe<Scalars['ID']>
  timeline?: InputMaybe<Scalars['String']>
  tool?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
  why?: InputMaybe<Scalars['String']>
}

export enum UserTaskItemFilterDateRange {
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  Today = 'TODAY',
}

export enum UserTaskItemStatus {
  Archived = 'ARCHIVED',
  Blocked = 'BLOCKED',
  Creating = 'CREATING',
  Deleted = 'DELETED',
  Done = 'DONE',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Todo = 'TODO',
}

export enum UserTaskQuickFilter {
  AllGoalsTasks = 'ALL_GOALS_TASKS',
  AllTasks = 'ALL_TASKS',
  NoProjectAssigned = 'NO_PROJECT_ASSIGNED',
}

export enum UserTaskSource {
  Assessment = 'ASSESSMENT',
  General = 'GENERAL',
  Goal = 'GOAL',
  Meeting = 'MEETING',
  Play = 'PLAY',
}

export enum UserTaskSuggestedItemStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type UserTasksFilter = {
  __typename?: 'UserTasksFilter'
  assigneeId?: Maybe<Array<Maybe<Scalars['String']>>>
  category?: Maybe<Array<Maybe<BusinessGoalCategory>>>
  dateRange?: Maybe<BusinessGoalDateFilter>
  milestoneId?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['String']>
  quickFilter?: Maybe<UserTaskQuickFilter>
  source?: Maybe<UserTaskSource>
  status?: Maybe<UserTaskItemStatus>
  taskPriority?: Maybe<Array<Maybe<BusinessGoalPriority>>>
}

export type UserTasksFilterInput = {
  assigneeId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category?: InputMaybe<Array<InputMaybe<BusinessGoalCategory>>>
  dateRange?: InputMaybe<BusinessGoalDateFilter>
  milestoneId?: InputMaybe<Scalars['String']>
  planId?: InputMaybe<Scalars['String']>
  quickFilter?: InputMaybe<UserTaskQuickFilter>
  source?: InputMaybe<UserTaskSource>
  status?: InputMaybe<UserTaskItemStatus>
  taskPriority?: InputMaybe<Array<InputMaybe<BusinessGoalPriority>>>
}

export type Validation = {
  __typename?: 'Validation'
  dateMax?: Maybe<DateValidationRule>
  dateMin?: Maybe<DateValidationRule>
  max?: Maybe<ValidationRule>
  min?: Maybe<ValidationRule>
  pattern?: Maybe<ValidationRule>
  required?: Maybe<ValidationRequired>
  type?: Maybe<ValidationTypes>
}

export type ValidationInput = {
  dateMax?: InputMaybe<DateValidationRuleInput>
  dateMin?: InputMaybe<DateValidationRuleInput>
  max?: InputMaybe<ValidationRuleInput>
  min?: InputMaybe<ValidationRuleInput>
  pattern?: InputMaybe<ValidationRuleInput>
  required?: InputMaybe<ValidationRequiredInput>
  type?: InputMaybe<ValidationTypes>
}

export type ValidationRequired = {
  __typename?: 'ValidationRequired'
  message?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Boolean']>
}

export type ValidationRequiredInput = {
  message?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['Boolean']>
}

export type ValidationRule = {
  __typename?: 'ValidationRule'
  message: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type ValidationRuleInput = {
  message: Scalars['String']
  value?: InputMaybe<Scalars['String']>
}

export enum ValidationTypes {
  Date = 'DATE',
  Float = 'FLOAT',
  Number = 'NUMBER',
  String = 'STRING',
}

export type Attendee = {
  __typename?: 'attendee'
  email: Scalars['String']
  responseStatus?: Maybe<Scalars['String']>
}

export type AttendeesInterface = {
  email: Scalars['String']
  organizer?: InputMaybe<Scalars['Boolean']>
  responseStatus?: InputMaybe<Scalars['String']>
  self?: InputMaybe<Scalars['Boolean']>
}

export type BusinessAdvisor = {
  __typename?: 'businessAdvisor'
  advisor?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CancelOutlookEventInput = {
  eventId: Scalars['String']
}

export type DeleteGoogleCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetOwnerBusinessDataInput = {
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type TaskPrerequisites = {
  __typename?: 'taskPrerequisites'
  assessments?: Maybe<Array<Scalars['String']>>
  plays?: Maybe<Array<Scalars['String']>>
}

export type TimeObjInterface = {
  dateTime?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type UpdateGoogleCalendarEventInput = {
  attendees: Array<InputMaybe<AttendeesInterface>>
  description?: InputMaybe<Scalars['String']>
  end: TimeObjInterface
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  start: TimeObjInterface
  tenantId?: InputMaybe<Scalars['String']>
}

export type UserMeeting = {
  __typename?: 'userMeeting'
  advisorId: Scalars['String']
  attendees: Array<Maybe<Attendee>>
  createdAt?: Maybe<Scalars['String']>
  endTime: Scalars['String']
  eventId: Scalars['String']
  id: Scalars['String']
  inviteeId?: Maybe<Scalars['String']>
  ownerId: Scalars['String']
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime: Scalars['String']
  status?: Maybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type GetMessagesFromBotFieldsFragment = {
  __typename?: 'MessageToBotResponse'
  hasMore: boolean
  nextCursor?: string | null | undefined
  messages?:
    | Array<{
        __typename?: 'ChatBotMessage'
        role: string
        content: string
        createdAt: string
        progress?: number | null | undefined
      }>
    | null
    | undefined
}

export type GetMessagesFromBotQueryVariables = Exact<{
  sessionId: Scalars['String']
  cursor?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetMessagesFromBotQuery = {
  __typename?: 'Query'
  getMessagesFromBot: {
    __typename?: 'MessageToBotResponse'
    hasMore: boolean
    nextCursor?: string | null | undefined
    messages?:
      | Array<{
          __typename?: 'ChatBotMessage'
          role: string
          content: string
          createdAt: string
          progress?: number | null | undefined
        }>
      | null
      | undefined
  }
}

export const GetMessagesFromBotFieldsFragmentDoc = `
    fragment getMessagesFromBotFields on MessageToBotResponse {
  messages {
    role
    content
    createdAt
    progress
  }
  hasMore
  nextCursor
}
    `
export const GetMessagesFromBotDocument = `
    query getMessagesFromBot($sessionId: String!, $cursor: String, $limit: Int) {
  getMessagesFromBot(sessionId: $sessionId, cursor: $cursor, limit: $limit) {
    ...getMessagesFromBotFields
  }
}
    ${GetMessagesFromBotFieldsFragmentDoc}`
export const useGetMessagesFromBotQuery = <TData = GetMessagesFromBotQuery, TError = any>(
  variables: GetMessagesFromBotQueryVariables,
  options?: UseQueryOptions<GetMessagesFromBotQuery, TError, TData>
) =>
  useQuery<GetMessagesFromBotQuery, TError, TData>(
    ['getMessagesFromBot', variables],
    fetcher<GetMessagesFromBotQuery, GetMessagesFromBotQueryVariables>(
      GetMessagesFromBotDocument,
      variables
    ),
    options
  )
export const useInfiniteGetMessagesFromBotQuery = <TData = GetMessagesFromBotQuery, TError = any>(
  variables: GetMessagesFromBotQueryVariables,
  options?: UseInfiniteQueryOptions<GetMessagesFromBotQuery, TError, TData>
) => {
  return useInfiniteQuery<GetMessagesFromBotQuery, TError, TData>(
    ['getMessagesFromBot.infinite', variables],
    (metaData) =>
      fetcher<GetMessagesFromBotQuery, GetMessagesFromBotQueryVariables>(
        GetMessagesFromBotDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}
