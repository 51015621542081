import React from 'react'
import useNavigateTo from 'hooks/useNavigateTo'
import { AppFC, IUpcomingEvent } from 'types'

import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import ActionCard from 'components/Common/ActionCard'
import { AppEventCard } from 'components/AppDashboard/AppEventCard/AppEventCard.component'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useTenantId } from 'utils/useTenantId'
import history from 'utils/history'

export interface IUpcomingEventsProps {
  events: IUpcomingEvent[]
  noEventsMessage: string
  loading?: boolean
  showInternalMeetingsOnly?: boolean
}

/** Displays event cards featuring details about a few most recent events for the user */
const AppDashboardUpcomingEvents: AppFC<IUpcomingEventsProps> = ({
  events = [],
  className = '',
  noEventsMessage = '',
  loading = false,
  showInternalMeetingsOnly = false,
}) => {
  const navigate = useNavigateTo('calendar')
  const userRole = getRoleFromPath()
  const [showAll, setShowAll] = React.useState(false)
  const tenantId = useTenantId()

  React.useEffect(() => {
    setShowAll(false)
  }, [showInternalMeetingsOnly])

  const noEventsScheduled = !loading && events.length === 0
  const displayedEvents = showAll ? events : events.slice(0, 2)
  const hasMoreEvents = events.length > 2

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center">
          <GradientCircleNotchIcon width="100" height="100" />
        </div>
      ) : (
        <div className="">
          {noEventsScheduled && (
            <p className="italic text-black-light text-lg sm:text-xl -mt-2">
              No {userRole === ROLES.V2 ? 'appointments yet!' : 'appointments today'}
            </p>
          )}
          <div className="space-y-4">
            {noEventsScheduled ? (
              <ActionCard onClick={() => navigate()}>
                <div>
                  <p className="leading-6 font-semibold text-lg">{noEventsMessage}</p>
                </div>
              </ActionCard>
            ) : (
              <>
                {displayedEvents.map((event, idx) => {
                  return (
                    <div
                      key={`event-card-${idx + 1}`}
                      className="flex items-stretch border border-app-grey-30 shadow-sm cursor-pointer rounded-lg"
                    >
                      <AppEventCard
                        description={event.description}
                        startDate={event.date}
                        endDate={event.endDate ?? ''}
                        eventId={event.eventId}
                        eventType={event.eventType}
                        className="flex-grow !rounded-none !border-none !bg-white !rounded-l-lg !w-[80%]"
                      />
                      <div
                        className="flex-grow bg-app-primary-70 text-white flex items-center justify-center rounded-r-lg font-inter font-medium text-sm w-[20%]"
                        onClick={() =>
                          history.push(
                            `/${getRoleFromPath()}/${tenantId}/calendar?eventId=${event.eventId}`
                          )
                        }
                      >
                        View
                      </div>
                    </div>
                  )
                })}
                {hasMoreEvents && !showAll && (
                  <button
                    onClick={() => setShowAll(true)}
                    className="py-2 px-4  flex items-center gap-2 border border-app-grey-30 rounded-lg"
                  >
                    <span className="text-app-black-100 font-normal text-sm">Show More</span>{' '}
                    <ChevronDownIcon className="w-4 h-4 text-app-black-100" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AppDashboardUpcomingEvents
